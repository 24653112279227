import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import {
  receivePaymentByFunction,
  getMTDForItemIds,
  getInvoiceItemForInvoiceId,
  reciveTriansactionDisplaySuccess,
} from "../../store/actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import classnames from "classnames";
import Moment from "moment";

const ReceivePayment = (props) => {
  const [togglePopUp, togglePopUpOps] = useState(false);
  const [invoiceItems, invoiceItemsOps] = useState([]);
  const [reciveTotal, reciveTotalOps] = useState(0);
  const [verifyTransaction, verifyTransactionOps] = useState(false);
  const [activeTraType, activeTraTypeOps] = useState("Cash");
  const [activeTab, setactiveTab] = useState("Cash");
  const [remark, remarkOps] = useState("");
  const [cashDepositDate, setCashDepositDate] = useState();
  const [chequeDate, chequeDateOps] = useState();
  const [chequeNumber, chequeNumberOps] = useState("");
  const [depositReference, depositReferenceOps] = useState("");
  const [mircCode, mircCodeOps] = useState("");
  const [bankName, bankNameOps] = useState("");
  const [onlineTransactionId, onlineTransactionIdOps] = useState("");
  const [paymentDate, paymentDateOps] = useState(new Date());
  const [showLoader, showLoaderOps] = useState(false);
  const [chequeDateError, chequeDateErrorOps] = useState("");
  const [bankNameError, bankNameErrorOps] = useState("");
  const [chequeNoError, chequeNoErrorOps] = useState("");
  const [totalRoyalty, setTotalRoyalty] = useState(0);
  const paymentMode = ["Cash", "Cheque", "Online"];
  const [penalty,setPenalty]= useState(0)
  const [penaltyObject,setPenaltyObject]= useState(undefined)

  useEffect(() => {
    if (props.teacherPaymenrWrite && props.paymentAdmin) {
      verifyTransactionOps(true);
    }
  }, [props.teacherPaymenrWrite, props.paymentAdmin]);

  useEffect(() => {
    if (props.school && props.school.attributes.additionalSettings &&
      props.school.attributes.additionalSettings.length > 5 && props.school.attributes.additionalSettings[5] > 0) {
      setPenalty(props.school.attributes.additionalSettings[5])
    }
  }, [props.school]);

  useEffect(() => {
    togglePopUpOps(props.receivePaymentModal);
  }, [props.receivePaymentModal]);
  useEffect(() => {
    if (props.activeInvoiceId) {
      props.getInvoiceItemForInvoiceId(props.activeInvoiceId);
      invoiceItemsOps([]);
      reciveTotalOps(0);
      if (props.teacherPaymenrWrite && props.paymentAdmin) {
        verifyTransactionOps(true);
      }
      activeTraTypeOps("Cash");
      setactiveTab("Cash");
      remarkOps("");
      chequeDateOps();
      chequeNumberOps("");
      mircCodeOps("");
      bankNameOps("");
      onlineTransactionIdOps("");
      paymentDateOps(new Date());
    }
  }, [props.activeInvoiceId]);
  useEffect(() => {
    if (props.invoiceItems.length > 0) {
      let tempVal = [];
      let itemIds = [];
      for (let val of props.invoiceItems) {
        let temp = {};
        temp.id = val.id;
        itemIds.push(val.id);
        temp.Obj = val;
        temp.itemDescription = val.attributes.itemDescription;
        temp.amount = +val.attributes.amount;
        temp.totalAmount = +val.attributes.totalAmount;
        temp.editAmount = +val.attributes.totalAmount;
        temp.maxPay = val.attributes.totalAmount;
        temp.paidAmount = 0;
        temp.isPaid = val.attributes.paidStatus === 1 ? true : false;
        if (val.attributes.paidStatus === 3) {
          temp.editAmount = temp.editAmount - temp.paidAmount;
        }
        temp.paidStatus = val.attributes.paidStatus;
        temp.isEnableForPay = false;
        if (temp.isPaid) {
          temp.isEnableTick = true;
        } else {
          temp.isEnableTick = false;
        }
        temp.royalty = val.attributes.royalty;
        temp.collectedRoyalty = val.attributes.collectedRoyalty;
        temp.oldTriansaction = [];
        temp.transactionIds = [];
        if (val.attributes.paidStatus === 1) {
          temp.paidCSS = "text-success";
        } else if (val.attributes.paidStatus === 2) {
          temp.paidCSS = "text-warning";
        } else {
          temp.paidCSS = "text-danger";
        }
        tempVal.push(temp);
      }
      invoiceItemsOps(tempVal);
      if (itemIds.length > 0) {
        props.getMTDForItemIds(itemIds);
      }
      
    }
  }, [props.invoiceItems]);
  useEffect(() => {
    let tempItems = Object.assign([], invoiceItems);
    for (let val of props.mtdIds) {
      for (let tempVal of tempItems) {
        if (val.attributes.itemId === tempVal.id) {
          if (val.attributes.isDeleted) {
            break;
          }
          if (!tempVal.transactionIds.includes(val.id)) {
            tempVal.oldTriansaction.push(val);
            tempVal.transactionIds.push(val.id);
            tempVal.editAmount -= val.attributes.amount;
            tempVal.maxPay -= val.attributes.amount;
            tempVal.paidAmount += val.attributes.amount;
            break;
          }
        }
      }
    }
    //invoiceItemsOps(tempItems);
    calculatePenaltyBasedOnSelectedItems(tempItems);
  }, [props.mtdIds]);

  // useEffect(() => {
  //   if (invoiceItems && invoiceItems.length > 0) {
  //     calculatePenaltyBasedOnSelectedItems()
  //   }
    
  // },[invoiceItems])
  
  

  const calculatePenaltyBasedOnSelectedItems = (exList) => {
    if (exList &&exList.length > 0) {

      
      let oldestItem;

      for (const val of exList) {

        if (val.id!= "penaltyObject" && val.Obj.attributes.dueDate ) {
          if (oldestItem) {
            if (oldestItem.Obj.attributes.dueDate > val.Obj.attributes.dueDate) {
              oldestItem = val;
            }
          } else {
            oldestItem = val;
          }
        }
      }

      let today = new Date();
      if (oldestItem && oldestItem.Obj.attributes.dueDate < today) {
        let dueDays = getDayBetweenDates(oldestItem.Obj.attributes.dueDate, today);

        if (dueDays > 0) {
          let temp = {};
          temp.id = 'penaltyObject';

          temp.itemDescription = 'Late payment penalty';
          temp.amount = dueDays * penalty;
          temp.totalAmount = dueDays * penalty;
          temp.editAmount = dueDays * penalty;
          temp.discount = 0;
          temp.discountType = 1;
          temp.paidAmount = 0;
          temp.isEnableForPay = true;
          temp.isPenalty = true
          temp.isEnableTick = true;

          let tempItems = exList ?? itemList;
           
          tempItems[tempItems.length-1].id=="penaltyObject"?tempItems[tempItems.length-1]=temp:tempItems.push(temp)
          
          setPenaltyObject(temp)
          invoiceItemsOps(tempItems);


        } else {
          invoiceItemsOps(exList)
        }
      } else {
        invoiceItemsOps(exList)
      }


    } else {
      invoiceItemsOps(exList)
    }
  }
  const getDayBetweenDates = (d1, d2) => {
    var diff = d2 - d1;
    return Math.floor(diff / 86400000);
  }

  useEffect(() => {
    if (props.showLoader) {
      showLoaderOps(props.showLoader);
    }
    if (props.reciveTransactionStat) {
      showLoaderOps(false);
      props.tog_receivePayment(false, true, true);
      props.reciveTriansactionDisplaySuccess();
      props.getAllInvoices();
    }
  }, [props.showLoader, props.reciveTransactionStat]);
  const enableTick = (id) => {
    let tempVals = Object.assign([], invoiceItems);
    for (let val of tempVals) {
      if (val.id === id) {
        val.isEnableTick = !val.isEnableTick;
        val.isEnableForPay = val.isEnableTick;
        if (val.royalty > 0 && val.isEnableTick) {
          let royalty = (val.editAmount * val.royalty) / 100;
          val.editAmountRoyalty = royalty;
        }
      }
    }
    invoiceItemsOps(tempVals);
    calculateTotal(tempVals);
  };
  const calculateTotal = (allValues) => {
    let tempTotal = 0;
    let totalRoyalty = 0;
    for (let val of allValues) {
      if (val.isEnableForPay) {
        tempTotal += val.editAmount;
        totalRoyalty += val.editAmountRoyalty;
      }
    }
    setTotalRoyalty(totalRoyalty);
    reciveTotalOps(tempTotal);
  };
  const onAmountChange = (id, newValue) => {
    let tempVals = Object.assign([], invoiceItems);
    for (let val of tempVals) {
      if (val.id === id) {
        if (newValue > val.maxPay) {
          val.editAmount = val.maxPay;
        } else {
          val.editAmount = +newValue;
        }
        if (val.royalty > 0 && val.isEnableTick) {
          let royalty = (val.editAmount * val.royalty) / 100;
          val.editAmountRoyalty = royalty;
        }
      }
    }
    invoiceItemsOps(tempVals);
    calculateTotal(tempVals);
  };
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const receivePayment = () => {
    let errorCount = 0;
    if (activeTab === "Cheque") {
      if (!chequeDate) {
        errorCount++;
        chequeDateErrorOps("Enter the cheque Date");
      } else {
        chequeDateErrorOps("");
      }
      if (!bankName) {
        errorCount++;
        bankNameErrorOps("Enter the bank name");
      } else {
        bankNameErrorOps("");
      }
      if (!chequeNumber) {
        errorCount++;
        chequeNoErrorOps("Enter the cheque number");
      } else {
        chequeNoErrorOps("");
      }
    }
    if (errorCount === 0) {
      let inputParam = {};
      inputParam.invoiceId = props.activeInvoice.id;
      inputParam.paymentType = activeTraType;
      //current userId
      // inputParam.userId = ''
      //current teacher id
      // inputParam.receivedBy = ''

      inputParam.cashDepositDate = cashDepositDate;
      inputParam.transactionStatus = remark;
      inputParam.amount = reciveTotal;
      if (chequeNumber) {
        inputParam.chequeNo = chequeNumber ? chequeNumber : "";
        inputParam.chequeDate = chequeDate;
        inputParam.micrCode = mircCode;
        inputParam.depositReference = depositReference;
      }
      inputParam.schoolId = props.schoolId;
      inputParam.transactionId = onlineTransactionId;
      inputParam.paymentDate = paymentDate;
      inputParam.bankName = bankName;
      inputParam.isVerified = verifyTransaction;
      if (totalRoyalty > 0) {
        inputParam.royaltyAmount = totalRoyalty;
        inputParam.schoolAmount = reciveTotal - totalRoyalty;
      }
      console.log(totalRoyalty);
      console.log(inputParam);
      props.receivePaymentByFunction(inputParam, invoiceItems);
    }
  };
  return (
    <Modal
      isOpen={togglePopUp}
      toggle={() => {
        props.tog_receivePayment(false, true);
      }}
      scrollable={true}
      size="xl"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {props.t("Receive Payment For")} : (
          {props.activeInvoice.invoiceNumber})
          <span className="text-info">{props.activeInvoice.childName}</span>
        </h5>
        <button
          type="button"
          onClick={() => {
            props.tog_receivePayment(false, true);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          disabled={showLoader}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">


        <Row>
          <Col className="d-flex justify-content-start">
            <h5 className="font-size-14 mb-4">{props.t("Balance")}</h5>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* <div className="d-flex justify-content-end mb-4"> */}
            <h5 className="font-size-14">
              <i className="bx bx-wallet text-primary font-size-16 align-middle mr-1"></i>{" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>{" "}
              {parseFloat(props.activeInvoice.balanceAmount).toFixed(2)}
            </h5>
            {/* </div> */}
          </Col>
        </Row>

        <div>
          <Nav pills className="bg-light rounded" role="tablist">
            <Row>
              <Col className="d-flex justify-content-start">
                {paymentMode.map((val, key) => (
                  <NavItem key={key} className="mt-3 mr-2">
                    <NavLink
                      className={classnames({ active: activeTab === val })}
                      onClick={() => {
                        activeTraTypeOps(val);
                        setactiveTab(val);
                        if (props.teacherPaymenrWrite && props.paymentAdmin) {
                          val === "Cash"
                            ? verifyTransactionOps(true)
                            : verifyTransactionOps(false);
                        }
                      }}
                    >
                      {val}
                    </NavLink>
                  </NavItem>
                ))}
              </Col>
              <Col className="d-flex justify-content-end">
                <NavItem className="">
                  <NavLink>
                    Transaction Date
                    <DatePicker
                      selected={paymentDate}
                      onChange={(date) => {
                        paymentDateOps(date);
                      }}
                      className="form-control"
                      placeholderText={props.t("Selecte Date")}
                      startDate={new Date()}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                    />
                  </NavLink>
                </NavItem>
              </Col>
            </Row>
          </Nav>
          <FormGroup className="mb-1 mt-3">
            {/* <Label className="mb-1">
                {activeTraType} {props.t("Details")}
            </Label> */}
            {activeTraType === "Cash" && (
              <AvForm className="needs-validation">
                <Row>
                  <Col md="3" xl="3" sm="12">
                    <FormGroup style={{ marginBottom: "-1rem" }}>
                      <Label htmlFor="validationCustom01">
                        {props.t("Cash Deposit Date")}
                      </Label>
                      <AvField
                        name="firstname"
                        placeholder="Cheque Date"
                        type="Date"
                        className="form-control"
                        value={cashDepositDate}
                        onChange={(e) => {
                          setCashDepositDate(e.target.value);
                        }}

                      />
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            )}
            {activeTraType === "Cheque" && (
              <AvForm className="needs-validation">
                <Row>
                  <Col md="3" xl="3" sm="12">
                    <FormGroup style={{ marginBottom: "-1rem" }}>
                      <Label htmlFor="validationCustom01">
                        {props.t("Date")}
                      </Label>
                      <AvField
                        name="firstname"
                        placeholder="Cheque Date"
                        type="Date"
                        className="form-control"
                        value={chequeDate}
                        onChange={(e) => {
                          chequeDateOps(e.target.value);
                          chequeDateErrorOps("");
                        }}

                      />
                    </FormGroup>
                    <small className="text-danger mt-0">
                      {chequeDateError}
                    </small>
                  </Col>
                  <Col md="2" xl="2" sm="12">
                    <FormGroup style={{ marginBottom: "-1rem" }}>
                      <Label htmlFor="validationCustom02">
                        {props.t("Number")}
                      </Label>
                      <AvField
                        name="lastname"
                        placeholder="Cheque Number"
                        type="text"
                        className="form-control"
                        value={chequeNumber}
                        onChange={(e) => {
                          chequeNumberOps(e.target.value);
                          chequeNoErrorOps("");
                        }}

                      />
                    </FormGroup>
                    <small className="text-danger mt-0">
                      {chequeNoError}
                    </small>
                  </Col>
                  <Col md="2" xl="2" sm="12">
                    <FormGroup>
                      <Label htmlFor="validationCustom03">
                        {props.t("MICR Code")}
                      </Label>
                      <AvField
                        name="MICR Code"
                        placeholder="MICR Code"
                        type="text"
                        className="form-control"
                        value={mircCode}
                        onChange={(e) => mircCodeOps(e.target.value)}

                      />
                    </FormGroup>
                  </Col>
                  <Col md="2" xl="2" sm="12">
                    <FormGroup style={{ marginBottom: "-1rem" }}>
                      <Label htmlFor="validationCustom04">
                        {props.t("Bank Name")}
                      </Label>
                      <AvField
                        name="Bank Name"
                        placeholder="Bank Name"
                        type="text"
                        className="form-control"
                        value={bankName}
                        onChange={(e) => {
                          bankNameOps(e.target.value);
                          bankNameErrorOps("");
                        }}

                      />
                    </FormGroup>
                    <small className="text-danger mt-0">
                      {bankNameError}
                    </small>
                  </Col>
                  <Col md="3" xl="3" sm="12">
                    <FormGroup>
                      <Label htmlFor="validationCustom04">
                        {props.t("Deposit Reference")}
                      </Label>
                      <AvField
                        name="Deposit Reference"
                        placeholder="Deposit Reference"
                        type="text"
                        className="form-control"
                        value={depositReference}
                        onChange={(e) =>
                          depositReferenceOps(e.target.value)
                        }

                      />
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            )}
            {activeTraType === "Online" && (
              <AvForm className="needs-validation mb-1 mt-3">
                <Row>
                  <Col md="6" xl="6" sm="12">
                    <FormGroup>
                      <Label htmlFor="validationCustom01">
                        {props.t("Reference Number")}
                      </Label>
                      <AvField
                        name="firstname"
                        placeholder="Online Reference Number"
                        type="text"
                        className="form-control"
                        value={onlineTransactionId}
                        onChange={(e) =>
                          onlineTransactionIdOps(e.target.value)
                        }

                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" xl="6" sm="12">
                    <FormGroup>
                      <Label htmlFor="validationCustom02">
                        {props.t("Bank Name")}
                      </Label>
                      <AvField
                        name="lastname"
                        placeholder="Bank Name"
                        type="text"
                        className="form-control"
                        value={bankName}
                        onChange={(e) => bankNameOps(e.target.value)}

                      />
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
            )}
          </FormGroup>

          <div className="mt-2">
            <Label>{props.t("Select Particular")} :</Label>
            {invoiceItems.length &&
              invoiceItems.map((val, key) => (
                <InputGroup className="mb-3" key={key}>
                  <InputGroupText key={key}
                    addonType="prepend"
                    style={{ minWidth: "80%", maxWidth: "80%" }}
                  >
                    <div className="custom-control custom-checkbox mt-2">
                      <input
                        type="checkbox"
                        checked={val.isEnableTick}
                        onChange={() => enableTick(val.id)}
                        className="custom-control-input"
                        id={`payment_${key}`}
                        disabled={val.isPaid}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`payment_${key}`}
                      ></label>
                    </div>
                    <div className="justify-content-start  m-2">
                      <Label
                        className="d-flex justify-content-start m-0"
                        style={{ minWidth: "100%", maxWidth: "100%" }}
                      >
                        {val.itemDescription} (
                        <span style={{ fontFamily: "Arial, sans-serif" }}>
                          {props.currency}
                        </span>{" "}
                        {parseFloat(val.totalAmount).toFixed(2)}) :
                        {val.paidAmount !== 0 && (
                          <span className={val.paidCSS}>
                            {" "}
                            ({props.t("paid")} :{" "}
                            <span style={{ fontFamily: "Arial, sans-serif" }}>
                              {props.currency}
                            </span>{" "}
                            {parseFloat(val.paidAmount).toFixed(2)})
                          </span>
                        )}
                      </Label>
                      {val.Obj && val.Obj.attributes.dueDate &&<p className="m-0 text-start">Due Date:{Moment(val.Obj.attributes.dueDate).format("DD-MM-YYYY")}</p>}
                    </div>
                    
                  </InputGroupText>
                  <Input
                    type="number"
                    style={{ minWidth: "20%", maxWidth: "20%" }}
                    value={val.editAmount}
                    className=" text-end"
                    disabled={!val.isEnableForPay} //val.id=="penaltyObject" ? true:
                    onChange={(e) => onAmountChange(val.id, e.target.value)}
                    min={1}
                    max={val.maxPay}
                  />
                </InputGroup>
              ))}
          </div>
          <hr />
          <Row>
            <Col md="12" sm="12" lg="12" className="mb-2">
              {props.teacherPaymenrWrite && props.paymentAdmin && (
                <div className="float-left">
                  <span className="badge badge-pill badge-soft-warning font-size-13 ml-2">
                    {props.t(" The transaction gets auto verified.")}
                  </span>
                  <Switch
                    className="ml-2"
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                      verifyTransactionOps(!verifyTransaction);
                    }}
                    checked={verifyTransaction}
                  />
                </div>
              )}
              <div className="float-right">
                <b className="h4">
                  {props.t("Total :")}{" "}
                  <span style={{ fontFamily: "Arial, sans-serif" }}>
                    {props.currency}
                  </span>{" "}
                  {parseFloat(reciveTotal).toFixed(2)}
                </b>
              </div>
            </Col>
            <Col md="12" sm="12" lg="12" className="mb-2">
              <Label>
                {props.t("Add remark about how and when it was paid")} :
              </Label>
              <Input
                type="textarea"
                id="textarea"
                onChange={(e) => {
                  remarkOps(e.target.value);
                }}
                maxLength="225"
                rows="3"
                placeholder="Remark."
                style={{ resize: "none" }}
                value={remark}
              />
            </Col>
          </Row>
        </div>

      </div>
      <div className="modal-footer">
        {!props.teacherPaymenrWrite && (
          <p className="text-danger mr-3">You don't have permission</p>
        )}
        <button
          type="button"
          onClick={() => {
            props.tog_receivePayment(false, true);
          }}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
          disabled={showLoader}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={receivePayment}
          disabled={
            props.teacherPaymenrWrite ? reciveTotal <= 0 || showLoader : true
          }
        >
          {showLoader && (
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
          )}
          {props.t("Receive")}
        </button>
      </div>
    </Modal>
  );
};
const mapStatetoProps = (state) => {
  const { triansactionsItems: mtdIds } = state.MoneyTransactionDetails;
  const { showLoader, reciveTransactionStat } = state.MoneyTransactions;
  const { invoiceItems } = state.InvoiceItems;
  return { mtdIds, invoiceItems, showLoader, reciveTransactionStat };
};
export default connect(mapStatetoProps, {
  receivePaymentByFunction,
  getMTDForItemIds,
  getInvoiceItemForInvoiceId,
  reciveTriansactionDisplaySuccess,
})(withRouter(withNamespaces()(ReceivePayment)));
