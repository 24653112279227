import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {

  getGroupMapsForGroupId,
  getInvoicesForFilters,
  getInvoicesCountForFilters,
  getVerifiedStatusForInvoices,
  deleteSelectedInvoice,
  notifyPaymentToParent,
  saveInvoice,
} from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Moment from "moment";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";

import ViewTriansaction from "./viewTriansaction";
import ViewInvoice from "./viewInvoice";
import ReceivePayment from "./receivePayment";
import PaymentLinkSharePopup from "./PaymentLinkSharePopup";

const AllInvoice = (props) => {
  let date = new Date();
  const [fromDate, fromDateOps] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30)
  );
  const [toDate, todateOps] = useState(new Date());
  const [activeYear, activeYearOps] = useState("All");
  const [activeFilterBy, activeFilterByOps] = useState("class");
  const [kidsList, kidListOps] = useState([]);
  const [filterByList, filterByListOps] = useState([]);
  const [activeFilterTo, activeFilterToOps] = useState("all");
  const [activeFilterKid, activeFilterKidOps] = useState("all");
  const [toggleId, toggleIdOps] = useState();
  const [kidDec, kidDecOps] = useState([]);
  const [invoices, invoicesOps] = useState([]);
  const [showLoader, showLoaderOps] = useState(true);
  const [togTriansaction, togTriansactionOps] = useState(false);
  const [activeTriansaction, activeTriansactionOps] = useState("");
  const [activeInvoiceId, activeInvoiceIdOps] = useState("");
  const [miniStat, miniStatOps] = useState({
    completedInvoiceCount: 0,
    completedInvoiceAmount: 0,
    partialPaidInvoiceCount: 0,
    partialPaidInvoiceAmount: 0,
    pendingInvoiceCount: 0,
    pendingInvoiceAmount: 0,
  });
  const [receivePaymentModal, receivePaymentModalOps] = useState(false);
  const [initLoad, initLoadOps] = useState(true);
  const [togInvoice, togInvoiceOps] = useState(false);
  const [activeInvoiceView, activeInvoiceViewOps] = useState("");
  const [receivePaymentForObj, receivePaymentForObjOps] = useState({});
  const [showConfirmDelete, showConfirmDeleteOps] = useState(false);
  const [deleteInvoiceId, deleteInvoiceIdOps] = useState("");
  const [dateOrder, dateOrderOps] = useState("ascending");
  const [nameOrder, nameOrderOps] = useState("ascending");
  const [invoiceOrder, invoiceOrderOps] = useState("ascending");
  const [payableOrder, payableOrderOps] = useState("ascending");
  const [receivedOrder, receivedOrderOps] = useState("ascending");
  const [balanceOrder, balanceOrderOps] = useState("ascending");
  const [statusOrder, statusOrderOps] = useState("ascending");
  const [sortByStatusValue, sortByStatusValueOps] = useState("");
  const [sortCompletedStyle, sortCompletedStyleOps] = useState(
    "bx bx-right-arrow-circle mt-2"
  );
  const [sortPartiallypaidStyle, sortPartiallypaidStyleOps] = useState(
    "bx bx-right-arrow-circle mt-2"
  );
  const [sortPendingStyle, sortPendingStyleOps] = useState(
    "bx bx-right-arrow-circle mt-2"
  );
  const [beforeSortOrgCopy, beforeSortOrgCopyOps] = useState([]);
  const [entryCount, entryCountOps] = useState("100");
  const [paginationList, paginationListOps] = useState([]);
  const [currentPage, currentPageOps] = useState("1");

  const [series, seriesOps] = useState([]);
  const [formDateDisplay, formDateDisplayOps] = useState("");
  const [toDateDisplay, toDateDisplayOps] = useState("");
  const [processFilterDate, processFilterDateOps] = useState(false);
  const entries = ["100", "500", "1000"];


  const [admissionKidDec, admissionKidDecOps] = useState([]);
  const [deletedKidDec, deletedKidDecOps] = useState([]);

  const [showEditInvoiceNumber, setShowEditInvoiceNumber] = useState(undefined)

  const [paymentSharePopup, setPaymentSharePopup] = useState(false);
  const [invoiceObject, setInvoiceObject] = useState();


  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${props.currency} ${val}`;
      },
      offsety: 100,
      style: {
        fontSize: "14px",
        fontWeight: 800,
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.7,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => `${props.currency}`,
        },
      },
    },
    legend: {
      show: false,
    },
    colors: ["#34c38f", "#f46a6a"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [`Paid Amount`, `Pending Amount`],
      labels: {
        show: false,
      },
    },
  };



  useEffect(() => {
    showLoaderOps(props.showLoader);
  }, [props.showLoader]);


  useEffect(() => {
    if (props.invoiceEditLoader === false) {
      setShowEditInvoiceNumber(undefined);
    }
  }, [props.invoiceEditLoader]);

  useEffect(() => {
    if (props.activeTab === "1") {
      getAllInvoices();
    }
  }, [props.activeTab]);




  useEffect(() => {
    if (props.classList) {
      let classIds = [];
      props.classList.map((val, key) => {
        classIds.push(val.id);
      });

      filterByListOps(props.classList);
      activeFilterByOps("class");
    }
  }, [props.classList]);
  const processKidData = (kids) => {
    if (kids) {
      let kidsTemp = [];
      kids.map((val, key) => {
        let temp = {};
        temp.Obj = val;
        temp.id = val.id;
        temp.name = val.attributes.Name;
        kidsTemp.push(temp);
      });
      kidListOps(kidsTemp);
    }
  };
  useEffect(() => {
    processKidData(props.kids);
    if (props.kids.length > 0) {
      let tempKids = [];
      for (const val of props.kids) {
        tempKids[val.id] = val;
      }
      kidDecOps(tempKids);
      // console.log(props.kids)
    }
  }, [props.kids]);

  useEffect(() => {
    if (props.admissionKids.length > 0) {
      let tempKids = [];
      for (const val of props.admissionKids) {
        tempKids[val.id] = val;
      }
      admissionKidDecOps(tempKids);
      // console.log(props.admissionKids);
    }
  }, [props.admissionKids]);

  useEffect(() => {
    if (props.allDeletedKids.length > 0) {
      let tempKids = [];
      for (const val of props.allDeletedKids) {
        tempKids[val.id] = val;
      }
      deletedKidDecOps(tempKids);
      // console.log(props.allDeletedKids);
    }
  }, [props.allDeletedKids]);


  useEffect(() => {
    switch (activeFilterBy) {
      case "class":
        filterByListOps(props.classList);
        toShowAllKids();
        if (!activeFilterBy) {
          activeFilterByOps("class");
        }
        break;
      case "group":
        filterByListOps(props.groupList);
        toShowAllKids();
        if (!activeFilterBy) {
          activeFilterByOps("class");
        }
        break;
      case "program":
        filterByListOps(props.programList);
        toShowAllKids();
        if (!activeFilterBy) {
          activeFilterByOps("class");
        }
        break;
      case "admission":
        filterByListOps([]);
        processKidData(props.admissionKids);
        break;
      default:
        activeFilterByOps("class");
    }
  }, [activeFilterBy]);
  const toShowAllKids = () => {
    let classIds = [];
    props.classList.map((val, key) => {
      classIds.push(val.id);
    });
    processKidData(props.kids);

  };

  useEffect(() => {
    if (activeFilterTo === "all") {
      toShowAllKids();
      filterByListOps(props.classList);
      if (!activeFilterTo) {
        activeFilterByOps("class");
      }
    } else if (activeFilterBy === "class") {
      let classIds = [];
      classIds.push(activeFilterTo);

      let classKids = props.kids && props.kids.filter(
        (kid) => (kid.attributes.ClassRoomID === activeFilterTo || kid.attributes.daycareClassId === activeFilterTo));

      processKidData(classKids);
    } else if (activeFilterBy === "group") {
      props.getGroupMapsForGroupId(activeFilterTo);
    } else if (activeFilterBy === "program") {
      props.programList.map((val, key) => {
        if (val.id === activeFilterTo) {
          props.getGroupMapsForGroupId(val.Obj.attributes.groupId);
        }
      });
    }
  }, [activeFilterTo]);
  useEffect(() => {
    let tempKids = [];
    props.groupmaps.map((val, key) => {
      if (val.attributes.userType === 1) {
        tempKids.push(val.attributes.userId);
      }
    });


    let groupKids = props.kids && props.kids.filter(
      (kid) => tempKids.includes(kid.id));

    processKidData(groupKids);
  }, [props.groupmaps]);
  useEffect(() => {
    if (props.successResCreateInvoice) {
      getAllInvoices();
      // toShowAllKids()
    }
  }, [props.successResCreateInvoice]);
  const getAllInvoices = () => {
    let filters = {};
    filters.containedIn = false;
    if (activeFilterBy) {
      if (activeFilterBy === "group" || activeFilterBy === "class") {
        if (activeFilterTo === "all" && activeFilterKid === "all") {
          filters.attributes = "schoolId";
          filters.value = props.schoolId;
          filters.containedIn = false;
        } else if (activeFilterTo && activeFilterKid === "all") {
          filters.attributes = "recipientId";
          filters.value = [];
          for (const ele of kidsList) {
            filters.value.push(ele.id);
          }
          filters.containedIn = true;
        } else {
          filters.attributes = "recipientId";
          filters.value = activeFilterKid;
          filters.containedIn = false;
        }
      } else if (activeFilterBy === "program") {
        if (activeFilterTo === "all") {
          filters.attributes = "programId";
          filters.value = [];
          for (const val of props.programList) {
            filters.value.push(val.id);
          }
          filters.containedIn = true;
        } else if (activeFilterTo && activeFilterKid === "all") {
          filters.attributes = "programId";
          filters.value = activeFilterTo;
          filters.containedIn = false;
        } else {
          filters.attributes = "programId";
          filters.value = activeFilterTo;
          filters.containedIn = false;
          filters.forRecipient = true;
          filters.recipientId = activeFilterKid;
        }
      } else if (activeFilterBy === "admission") {
        if (activeFilterKid === "all") {
          filters.attributes = "recipientId";
          filters.value = [];
          for (const ele of kidsList) {
            filters.value.push(ele.id);
          }
          filters.containedIn = true;
        } else {
          filters.attributes = "recipientId";
          filters.value = activeFilterKid;
          filters.containedIn = false;
        }
      }
    }
    if (fromDate && toDate) {
      filters.fromDate = fromDate;
      filters.toDate = toDate;
    } else {
      filters.fromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 30
      );
      filters.toDate = new Date();
      fromDateOps(
        new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30)
      );
      todateOps(new Date());
    }
    formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
    toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
    if (activeYear && activeYear !== "All") {
      let tempTags = [];
      if (activeYear === "2017-18") {
        tempTags.push("2017 - 18");
        tempTags.push("2017-18");
        tempTags.push("2017- 18");
        tempTags.push("2017 -18");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2017");
          filters.toDate = new Date("5-1-2018");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2018-19") {
        tempTags.push("2018 - 19");
        tempTags.push("2018-19");
        tempTags.push("2018- 19");
        tempTags.push("2018 -19");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2018");
          filters.toDate = new Date("5-1-2019");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2019-20") {
        tempTags.push("2019 - 20");
        tempTags.push("2019-20");
        tempTags.push("2019- 20");
        tempTags.push("2019 -20");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2019");
          filters.toDate = new Date("5-1-2020");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2020-21") {
        tempTags.push("2020 - 21");
        tempTags.push("2020-21");
        tempTags.push("2020- 21");
        tempTags.push("2020 -21");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2020");
          filters.toDate = new Date("5-1-2021");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2021-22") {
        tempTags.push("2021 - 22");
        tempTags.push("2021-22");
        tempTags.push("2021- 22");
        tempTags.push("2021 -22");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2021");
          filters.toDate = new Date("5-1-2022");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2022-23") {
        tempTags.push("2022 - 23");
        tempTags.push("2022-23");
        tempTags.push("2022- 23");
        tempTags.push("2022 -23");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2022");
          filters.toDate = new Date("5-1-2023");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2023-24") {
        tempTags.push("2023 - 24");
        tempTags.push("2023-24");
        tempTags.push("2023- 24");
        tempTags.push("2023 -24");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2023");
          filters.toDate = new Date("5-1-2024");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      } else if (activeYear === "2024-25") {
        tempTags.push("2024 - 25");
        tempTags.push("2024-25");
        tempTags.push("2024- 25");
        tempTags.push("2024 -25");
        if (processFilterDate) {
          filters.fromDate = new Date("1-1-2024");
          filters.toDate = new Date("5-1-2025");
          fromDateOps(filters.fromDate);
          todateOps(filters.toDate);
          formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
          toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
          processFilterDateOps(false);
        }
      }

      filters.tags = tempTags;
    } else if (processFilterDate) {
      filters.fromDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 30
      );
      filters.toDate = new Date();
      fromDateOps(filters.fromDate);
      todateOps(filters.toDate);
      formDateDisplayOps(Moment(filters.fromDate).format("DD-MM-YYYY"));
      toDateDisplayOps(Moment(filters.toDate).format("DD-MM-YYYY"));
      processFilterDateOps(false);
    }
    filters.limit = +entryCount;
    filters.skipValue = currentPage;

    props.getInvoicesCountForFilters(filters, props.schoolId);
    props.getInvoicesForFilters(filters, props.schoolId);
  };
  useEffect(() => {
    getAllInvoices();
  }, [activeYear]);
  useEffect(() => {
    if (initLoad) {
      if (
        activeFilterBy &&
        props.schoolId &&
        activeFilterKid &&
        kidsList.length > 0 &&
        activeFilterTo &&
        fromDate &&
        toDate &&
        activeYear
      ) {
        getAllInvoices();
        initLoadOps(false);
      }
    }
  }, [
    activeFilterBy,
    props.schoolId,
    activeFilterKid,
    activeFilterTo,
    kidsList,
    fromDate,
    toDate,
    activeYear,
  ]);
  const calculateMiniStat = (invocies) => {
    let tempMap = {
      completedInvoiceCount: 0,
      completedInvoiceAmount: 0,
      partialPaidInvoiceCount: 0,
      partialPaidInvoiceAmount: 0,
      pendingInvoiceCount: 0,
      pendingInvoiceAmount: 0,
    };
    for (const val of invocies) {
      if (val.attributes.paidStatus === 1) {
        tempMap.completedInvoiceCount += 1;
        tempMap.completedInvoiceAmount += val.attributes.paidAmount;
      } else if (val.attributes.paidStatus === 3) {
        tempMap.partialPaidInvoiceCount += 1;
        tempMap.completedInvoiceAmount += val.attributes.paidAmount;
        tempMap.pendingInvoiceAmount +=
          +val.attributes.amount - val.attributes.paidAmount;
      } else {
        tempMap.pendingInvoiceCount += 1;
        tempMap.pendingInvoiceAmount += +val.attributes.amount;
      }
    }
    const tempseries = [
      {
        data: [tempMap.completedInvoiceAmount, tempMap.pendingInvoiceAmount],
      },
    ];
    miniStatOps(tempMap);
    seriesOps(tempseries);
  };
  useEffect(() => {
    let tempInvoice = [];
    let invoicesIds = [];
    if (props.invoices) {
      calculateMiniStat(props.invoices);
      for (const val of props.invoices) {
        let temp = {};
        temp.Obj = val;
        temp.id = val.id;
        invoicesIds.push(val.id);
        temp.invoiceNumber = val.attributes.invoiceNumber;
        temp.paymentOptionId = val.attributes.paymentOptionId;
        temp.dateorg = val.attributes.createdAt;
        temp.date = val.attributes.createdAt
          ? Moment(val.attributes.createdAt).format("DD-MMM-YYYY")
          : "--";
        temp.childId = val.attributes.recipientId;
        temp.childName = getKidName(val.attributes.recipientId);

        temp.invoiceTitle = val.attributes.title;
        temp.status = {};
        temp.paidStatus = val.attributes.paidStatus
          ? val.attributes.paidStatus
          : 0;
        if (val.attributes.paidStatus === 1) {
          temp.statusOrder = "paid";
          temp.status.stat = "Paid";
          temp.status.color = "success m-0 p-0";
          temp.status.sortValue = "completed";
        } else if (val.attributes.paidStatus === 3) {
          temp.statusOrder = "partiallypaid";
          temp.status.stat = "Partially Paid";
          temp.status.color = "warning m-0 p-0";
          temp.status.sortValue = "partiallypaid";
        } else {
          temp.statusOrder = "pending";
          temp.status.stat = "Pending";
          temp.status.color = "danger m-0 p-0";
          temp.status.sortValue = "pending";
        }
        if (val.attributes.tags) {
          temp.tag = val.attributes.tags[0] ? val.attributes.tags[0] : "";
        }
        temp.totalAmount = val.attributes.amount ? val.attributes.amount : "0";
        temp.paidAmount = val.attributes.paidAmount
          ? val.attributes.paidAmount
          : 0;
        temp.balanceAmount = +temp.totalAmount - temp.paidAmount;
        temp.isActionActive = false;
        temp.showPendingVerify = false;
        temp.programId = val.attributes.programId;
        temp.receiptAddressId = val.attributes.receiptAddressId;
        tempInvoice.push(temp);
      }
      invoicesOps(tempInvoice);
      sortByStatusValueOps("");
      sortCompletedStyleOps("bx bx-right-arrow-circle mt-2");
      sortPartiallypaidStyleOps("bx bx-right-arrow-circle mt-2");
      sortPendingStyleOps("bx bx-right-arrow-circle mt-2");
      beforeSortOrgCopyOps([]);
      if (invoicesIds.length > 0) {
        props.getVerifiedStatusForInvoices(invoicesIds);
      }
    }
  }, [props.invoices]);
  useEffect(() => {
    if (props.pendingVerifyMT) {
      let tempInvoice = Object.assign([], invoices);
      for (const val of tempInvoice) {
        for (const val1 of props.pendingVerifyMT) {
          if (
            !val.showPendingVerify &&
            !val1.attributes.deleted &&
            val1.attributes.invoiceId === val.id &&
            val1.attributes.transactionType !== "AirPay" &&
            val1.attributes.transactionType !== "RazorPay" &&
            val1.attributes.transactionType !== "OnePay" 
          ) {
            val.showPendingVerify = true;
          }
        }
      }
      invoicesOps(tempInvoice);
    }
  }, [props.pendingVerifyMT]);

  const activeDropDown = (id) => {
    let tempVals = Object.assign([], invoices);
    for (let val of tempVals) {
      if (val.id === id) {
        val.isActionActive = !val.isActionActive;
      } else {
        val.isActionActive = false;
      }
    }
    invoicesOps(tempVals);
  };
  const tog_triansaction = (id) => {
    if (id) {
      activeTriansactionOps(id);
    } else {
      activeTriansactionOps("");
    }
    if (togTriansaction) {
      getAllInvoices();
    }
    togTriansactionOps(!togTriansaction);
  };
  const tog_invoice = (id, isPaymentSuccess) => {
    if (id) {
      activeInvoiceViewOps(id);
      activeInvoiceIdOps(id);
    } else {
      activeInvoiceViewOps("");
      activeInvoiceIdOps("");
    }
    if (id && togInvoice) {
      getAllInvoices();
    }
    if (!id && isPaymentSuccess && togInvoice) {
      togInvoiceOps(!togInvoice);
    } else if (!isPaymentSuccess) {
      togInvoiceOps(!togInvoice);
    }
  };
  const getInvoice = (id) => {
    for (const val of invoices) {
      if (val.id === id) {
        return val;
      }
    }
    return {};
  };
  const tog_receivePayment = (invoice, setEmpty, isPaymentSuccess) => {
    if (invoice) {
      receivePaymentForObjOps(invoice);
    } else {
      if (setEmpty) {
        receivePaymentForObjOps({});
      }
    }
    if (isPaymentSuccess && receivePaymentModal) {
      receivePaymentModalOps(!receivePaymentModal);
    } else if (!isPaymentSuccess) {
      receivePaymentModalOps(!receivePaymentModal);
    }
  };
  const confirmDelete = (id) => {
    deleteInvoiceIdOps(id);
    showConfirmDeleteOps(true);
  };
  const handelDeleteInvoice = () => {
    props.deleteSelectedInvoice(deleteInvoiceId);
    showConfirmDeleteOps(false);
  };
  useEffect(() => {
    if (props.deleteSuccess) {
      showConfirmDeleteOps(false);
      deleteInvoiceIdOps("");
      toastr.success("Invoice Deleted Successfully", { timeOut: 1500 });
    }
  }, [props.deleteSuccess]);
  const sendNotifyToParent = (invoiceId, kidId) => {
    props.notifyPaymentToParent(invoiceId, kidId);
  };



  const getKidName = (kidId) => {
    let childName = "--";
    if (kidDec[kidId]) {
      childName = kidDec[kidId].attributes.Name;
    } else if (admissionKidDec[kidId]) {
      childName = admissionKidDec[kidId].attributes.Name;
    }
    else if (deletedKidDec[kidId]) {
      childName = deletedKidDec[kidId].attributes.Name;
    }

    return childName;
  }

  useEffect(() => {
    if (invoices.length > 0) {
      let gotValue = false;
      let tempInvoice = Object.assign([], invoices);
      for (const val of tempInvoice) {
        if (!val.childName || val.childName === "--") {
          if (val.childId) {
            if (kidDec[val.childId]) {
              gotValue = true;
              val.childName = kidDec[val.childId].attributes.Name;
            } else if (admissionKidDec[val.childId]) {
              gotValue = true;
              val.childName = admissionKidDec[val.childId].attributes.Name;
            } else if (deletedKidDec[val.childId]) {
              gotValue = true;
              val.childName = deletedKidDec[val.childId].attributes.Name;
            } else {
              val.childName = "--";
            }
          }
        }
      }
      if (gotValue) {
        invoicesOps(invoices);
      }
    }
  }, [invoices, kidDec, deletedKidDec, admissionKidDec]);


  const sortBy = (type, order) => {
    let sortingFor = "";
    let sortFor = order === "ascending" ? false : true;
    if (type === "invoiceNumber") {
      // invoiceOrderOps('ascending')
      dateOrderOps("ascending");
      nameOrderOps("ascending");
      payableOrderOps("ascending");
      receivedOrderOps("ascending");
      balanceOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "invoiceNumber";
    } else if (type === "dateorg") {
      invoiceOrderOps("ascending");
      // dateOrderOps("ascending");
      nameOrderOps("ascending");
      payableOrderOps("ascending");
      receivedOrderOps("ascending");
      balanceOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "dateorg";
    } else if (type === "childName") {
      invoiceOrderOps("ascending");
      dateOrderOps("ascending");
      // nameOrderOps("ascending");
      payableOrderOps("ascending");
      receivedOrderOps("ascending");
      balanceOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "childName";
    } else if (type === "totalAmount") {
      invoiceOrderOps("ascending");
      dateOrderOps("ascending");
      nameOrderOps("ascending");
      // payableOrderOps("ascending");
      receivedOrderOps("ascending");
      balanceOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "totalAmount";
    } else if (type === "paidAmount") {
      invoiceOrderOps("ascending");
      dateOrderOps("ascending");
      nameOrderOps("ascending");
      payableOrderOps("ascending");
      // receivedOrderOps("ascending");
      balanceOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "paidAmount";
    } else if (type === "balanceAmount") {
      invoiceOrderOps("ascending");
      dateOrderOps("ascending");
      nameOrderOps("ascending");
      payableOrderOps("ascending");
      receivedOrderOps("ascending");
      // balanceOrderOps("ascending");
      statusOrderOps("ascending");
      sortingFor = "balanceAmount";
    } else if (type === "status") {
      invoiceOrderOps("ascending");
      dateOrderOps("ascending");
      nameOrderOps("ascending");
      payableOrderOps("ascending");
      receivedOrderOps("ascending");
      balanceOrderOps("ascending");
      // statusOrderOps("ascending")
      sortingFor = "statusOrder";
    }
    let tempInvoices = Object.assign([], invoices);
    let sortedList = tempInvoices.sort(function (a, b) {
      if (sortFor) {
        if (a[sortingFor] < b[sortingFor]) {
          return -1;
        }
        if (a[sortingFor] > b[sortingFor]) {
          return 1;
        }
        return 0;
      } else {
        if (a[sortingFor] < b[sortingFor]) {
          return 1;
        }
        if (a[sortingFor] > b[sortingFor]) {
          return -1;
        }
        return 0;
      }
    });
    invoicesOps(sortedList);
  };
  const sortByStatus = (type) => {
    if (sortByStatusValue === type) {
      let paidAmount = 0;
      let pendingAmount = 0;
      for (const val of beforeSortOrgCopy) {
        paidAmount += val.paidAmount;
        pendingAmount += val.totalAmount - val.paidAmount;
      }
      const tempseries = [
        {
          data: [paidAmount, pendingAmount],
        },
      ];
      seriesOps(tempseries);
      sortByStatusValueOps("");
      sortCompletedStyleOps("bx bx-right-arrow-circle mt-2");
      sortPartiallypaidStyleOps("bx bx-right-arrow-circle mt-2");
      sortPendingStyleOps("bx bx-right-arrow-circle mt-2");
      invoicesOps(beforeSortOrgCopy);
      beforeSortOrgCopyOps([]);
    } else {
      sortByStatusValueOps(type);
      if (type === "completed") {
        sortCompletedStyleOps("bx bx-right-arrow-circle mt-2");
        sortPartiallypaidStyleOps("");
        sortPendingStyleOps("");
      } else if (type === "partiallypaid") {
        sortCompletedStyleOps("");
        sortPartiallypaidStyleOps("bx bx-right-arrow-circle mt-2");
        sortPendingStyleOps("");
      } else if (type === "pending") {
        sortCompletedStyleOps("");
        sortPartiallypaidStyleOps("");
        sortPendingStyleOps("bx bx-right-arrow-circle mt-2");
      }
      displaySortByStatus(type);
    }
  };
  const displaySortByStatus = (type) => {
    let copyInvoice = beforeSortOrgCopy;
    if (beforeSortOrgCopy.length === 0 && invoices.length > 0) {
      let tempInvoices = Object.assign([], invoices);
      copyInvoice = tempInvoices;
      beforeSortOrgCopyOps(tempInvoices);
    }
    let sortedList = [];
    let paidAmount = 0;
    let pendingAmount = 0;
    for (const val of copyInvoice) {
      if (val.status.sortValue === type) {
        sortedList.push(val);
        paidAmount += val.paidAmount;
        pendingAmount += val.totalAmount - val.paidAmount;
      }
    }
    const tempseries = [
      {
        data: [paidAmount, pendingAmount],
      },
    ];
    seriesOps(tempseries);
    invoicesOps(sortedList);
  };
  useEffect(() => {
    if (props.invoiceCount === 0) {
      paginationListOps(["1"]);
    } else {
      const countPages = props.invoiceCount / +entryCount;
      const pages = Math.ceil(countPages);
      let pagination = [];
      for (let i = 1; i <= pages; i++) {
        pagination.push("" + i);
      }
      paginationListOps(pagination);
    }
  }, [props.invoiceCount]);
  useEffect(() => {
    getAllInvoices();
  }, [currentPage, entryCount]);


  const getYearDropDown = () => {
    //dropdown for list
    let currentState = activeYear;
    let newList = props.list.filter((item) => item !== currentState);
    if (!props.list.includes("All") && currentState !== "All") {
      newList.unshift("All");
    }

    return (
      <Dropdown
        isOpen={toggleId === currentState}
        toggle={() => toggleIdOps(toggleId ? undefined : currentState)}
      >
        <DropdownToggle className="bg-white border-0">
          <Badge
            className="font-size-12 badge-soft-success"
            color="success"
            pill
          >
            {currentState}
            <i className="mdi mdi-chevron-down"></i>
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {newList.map((item, key) => (
            <DropdownItem
              key={key}
              onClick={() => {
                activeYearOps(item);
                processFilterDateOps(true);
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <React.Fragment>
      {showConfirmDelete && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            showConfirmDeleteOps(false);
          }}
          onConfirm={() => handelDeleteInvoice()}
        >
          {props.t("Are you sure you want to delete invoice")} <br></br>
        </SweetAlert>
      )}
      {receivePaymentModal && <ReceivePayment
        receivePaymentModal={receivePaymentModal}
        tog_receivePayment={tog_receivePayment}
        activeInvoice={receivePaymentForObj}
        currency={props.currency}
        activeInvoiceId={receivePaymentForObj.id}
        getAllInvoices={getAllInvoices}
        schoolId={props.schoolId}
        teacherPaymenrWrite={props.teacherPaymenrWrite}
        paymentAdmin={props.paymentAdmin}
        showRoyalty={props.showRoyalty}
        school={props.school}
      />}
      {togTriansaction && <ViewTriansaction
        invoice={getInvoice(activeTriansaction)}
        currency={props.currency}
        activeInvoiceId={activeTriansaction}
        togTriansaction={togTriansaction}
        tog_triansaction={(reload) => {
          if (reload) {
            tog_triansaction();
          } else {
            activeTriansactionOps("");
            togTriansactionOps(!togTriansaction);
          }
        }}
        schoolId={props.schoolId}
        teacherPaymenrWrite={props.teacherPaymenrWrite}
        paymentAdmin={props.paymentAdmin}
        showRoyalty={props.showRoyalty}
      />}
      {togInvoice && <ViewInvoice
        id="viewInvoice"
        invoice={getInvoice(activeInvoiceId)}
        togInvoice={togInvoice}
        tog_invoice={tog_invoice}
        activeInvoiceView={activeInvoiceView}
        yearList={props.list}
        currency={props.currency}
        paymentOptions={props.paymentOptions}
        schoolId={props.schoolId}
        teacherPaymenrWrite={props.teacherPaymenrWrite}
        getAllInvoices={getAllInvoices}
        showRoyalty={props.showRoyalty}
        completeDisable={props.completeDisable}
      />}
      <Card>
        <CardBody style={{ marginTop: "-2.5rem" }}>
          <Container className="mb-1 py-1">
            <Row>
              <Col
                style={{ marginRight: "-2.2rem", marginTop: "-0.1rem" }}
              >
                <div className="d-flex justify-content-end m-2">
                  {getYearDropDown()}
                  <i className="mdi mdi-18px mdi-filter"></i>
                </div>
              </Col>
            </Row>
            <Row className="bg-light ">
              <Col md="2" xs="12" xl="2">
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">{props.t("Filter by")} :</Label>
                  <select
                    className="form-control "
                    value={activeFilterBy}
                    onChange={(e) => {
                      activeFilterByOps(e.target.value);
                    }}
                  >
                    {props.filterList.map((val, key) => (
                      <option key={key} value={val}>
                        {props.t(
                          `${val.charAt(0).toUpperCase()}${val.slice(1)}`
                        )}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md="2" xs="12" xl="2">
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">{props.t("Filter to")} :</Label>
                  <select
                    className="form-control "
                    value={activeFilterTo}
                    onChange={(e) => {
                      activeFilterToOps(e.target.value);
                    }}
                    placeholder="Select Program"
                  >
                    <option value="all">{props.t("All")}</option>
                    {filterByList.map((val, key) => (
                      <option key={key} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md="2" xs="12" xl="2">
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">{props.t("Start")} :</Label>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => {
                      fromDateOps(date);
                    }}
                    className="form-control"
                    placeholderText={props.t("Select Date")}
                    startDate={fromDate}
                    // endDate={toDate}
                    maxDate={toDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </FormGroup>
              </Col>
              <Col md="2" xs="12" xl="2">
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">{props.t("End")} :</Label>
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => todateOps(date)}
                    className="form-control"
                    placeholderText={props.t("Select Date")}
                    startDate={fromDate}
                    // endDate={toDate}
                    minDate={fromDate}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  />
                </FormGroup>
              </Col>
              <Col md="3" xs="12" xl="3">
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">
                    {props.t("Filter by Student")} :
                  </Label>
                  <select
                    className="form-control "
                    value={activeFilterKid}
                    onChange={(e) => {
                      activeFilterKidOps(e.target.value);
                    }}
                  >
                    <option value="all">{props.t("All")}</option>
                    {kidsList.length === 0 && (
                      <option className="text-danger text-center" disabled>
                        {props.t("No Children")}
                      </option>
                    )}
                    {kidsList.map((val, key) => (
                      <option key={key} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md="1" xs="12" xl="1">
                <div className="mt-2">
                  <button
                    onClick={getAllInvoices}
                    className="btn btn-primary float-right mt-4 mr-2"
                  >
                    {props.t("Apply")}
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="mb-1 mt-1">
              <Col sm="3">
                <ul className="verti-timeline list-unstyled">
                  <li
                    className="event-list mb-0 pb-2 d-flex align-items-center"
                    onClick={() => sortByStatus("pending")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="event-timeline-dot">
                      <i className={sortPendingStyle}></i>
                    </div>
                    <div className="mr-3">
                      <i className="mdi mdi-credit-card-outline h2 text-danger" style={{ marginRight: "1rem" }}></i>
                    </div>
                    <Media>
                      <div className="mt-1">
                        <span className="badge badge-pill badge-soft-danger font-size-13">
                          Pending({miniStat.pendingInvoiceCount})
                        </span>
                      </div>
                    </Media>
                  </li>
                  <li
                    className="event-list mb-0 pb-2 d-flex align-items-center"
                    onClick={() => sortByStatus("partiallypaid")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="event-timeline-dot">
                      <i className={sortPartiallypaidStyle}></i>
                    </div>
                    <div className="mr-3">
                      <i className="mdi mdi-account-cash-outline h2 text-warning" style={{ marginRight: "1rem" }}></i>
                    </div>
                    <Media body>
                      <div className="mt-1">
                        <span className="badge badge-pill badge-soft-warning font-size-13">
                          Partially Paid ({miniStat.partialPaidInvoiceCount})
                        </span>
                      </div>
                    </Media>
                  </li>
                  <li
                    className="event-list d-flex align-items-center"
                    onClick={() => sortByStatus("completed")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="event-timeline-dot">
                      <i className={sortCompletedStyle}></i>
                    </div>
                    <div className="mr-3">
                      <i className="mdi mdi-bank h2 text-success" style={{ marginRight: "1rem" }}></i>
                    </div>
                    <Media body>
                      <div className="mt-1">
                        <span className="badge badge-pill badge-soft-success font-size-13">
                          Complete ({miniStat.completedInvoiceCount})
                        </span>
                      </div>
                    </Media>
                  </li>
                </ul>
              </Col>
              <Col sm="9">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height="120"
                />
              </Col>
              {/* <Col sm="4">
                <Card
                  onClick={() => sortByStatus("completed")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody style={sortCompletedStyle}>
                    <Row>
                      <Col md="3" xl="3" xs="3">
                        <i
                          className={`mdi mdi-bank h1 text-success align-middle mb-0 mr-3`}
                        ></i>
                      </Col>
                      <Col md="9" xl="9" xs="9">
                        <p className="text-muted h5 mb-2">
                          {props.t("Completed")}(
                          <span className="text-success">
                            {miniStat.completedInvoiceCount}
                          </span>
                          )
                        </p>
                        <div>
                          <p className="text-muted text-truncate mb-0">
                            (
                            <span style={{ fontFamily: "Arial, sans-serif" }}>
                              {props.currency}
                            </span>
                            ){" "}
                            {parseFloat(
                              miniStat.completedInvoiceAmount
                            ).toFixed(2)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="4">
                <Card
                  onClick={() => sortByStatus("partiallypaid")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody style={sortPartiallypaidStyle}>
                    <Row>
                      <Col
                        md="3"
                        xl="3"
                        xs="3"
                        // onClick={() =>changePaymentMode('partiallypaid')} className={activePaymentType === 'partiallypaid' ? 'bg-light pt-1 rounded-circle' : ''}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="mdi mdi-account-cash-outline h1 text-warning align-middle mb-0 mr-3"></i>
                      </Col>
                      <Col md="9" xl="9" xs="9">
                        <p className="text-muted h5 mb-2">
                          {props.t("Partially Paid")}(
                          <span className="text-warning">
                            {miniStat.partialPaidInvoiceCount}
                          </span>
                          )
                        </p>
                        <div>
                          <p className="text-muted text-truncate mb-0">
                            (
                            <span style={{ fontFamily: "Arial, sans-serif" }}>
                              {props.currency}
                            </span>
                            ){" "}
                            {parseFloat(
                              miniStat.partialPaidInvoiceAmount
                            ).toFixed(2)}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="4">
                <Card
                  onClick={() => sortByStatus("pending")}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody style={sortPendingStyle}>
                    <Row>
                      <Col
                        md="3"
                        xl="3"
                        xs="3"
                        // onClick={() =>changePaymentMode('pending')} className={activePaymentType === 'pending' ? 'bg-light pt-1 pb-1 rounded-circle' : ''}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="mdi mdi-credit-card-outline h1 text-danger align-middle mb-0 mr-3"></i>
                      </Col>
                      <Col md="9" xl="9" xs="9">
                        <p className="text-muted h5 mb-2">
                          {props.t("Pending")}(
                          <span className="text-danger">
                            {miniStat.pendingInvoiceCount}
                          </span>
                          )
                        </p>
                        <div>
                          <p className="text-muted h6 text-truncate mb-0">
                            (
                            <span style={{ fontFamily: "Arial, sans-serif" }}>
                              {props.currency}
                            </span>
                            ){" "}
                            {parseFloat(miniStat.pendingInvoiceAmount).toFixed(
                              2
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
          <div className="table-responsive">
            <Row>
              <Col md="8" sm="8" lg="8">
                <h5 className="text-primary">
                  {props.t("Showing")}
                  <select
                    className="custom-select ml-1"
                    value={entryCount}
                    style={{ width: "15%" }}
                    onChange={(e) => {
                      entryCountOps(e.target.value);
                      getAllInvoices();
                    }}
                  >
                    {entries.map((val, key) => (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                  {props.t(" invoices from")} {formDateDisplay} {props.t("to")}{" "}
                  {toDateDisplay}
                </h5>
              </Col>
              <Col md="4" sm="4" lg="4">
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-end"
                >
                  <PaginationItem disabled={currentPage === "1"}>
                    <PaginationLink
                      onClick={() => currentPageOps(+currentPage - 1 + "")}
                      href="#"
                      tabIndex="-1"
                    >
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  {paginationList.map((val, key) => (
                    <PaginationItem active={currentPage === val} key={key}>
                      <PaginationLink
                        onClick={() => currentPageOps(val)}
                        href="#"
                      >
                        {val}{" "}
                        {currentPage === val && (
                          <span className="sr-only">(current)</span>
                        )}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={currentPage === paginationList.length + ""}
                  >
                    <PaginationLink
                      href="#"
                      onClick={() => currentPageOps(+currentPage + 1 + "")}
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
            <table
              className={
                invoices.length === 1
                  ? `table table-centered table-nowrap mb-5`
                  : `table table-centered table-nowrap mb-0`
              }
            >
              <thead className="thead-light">
                <tr>
                  {/* <th>#</th> */}
                  <th
                    onClick={() => {
                      invoiceOrder === "ascending"
                        ? invoiceOrderOps("descending")
                        : invoiceOrderOps("ascending");
                      sortBy("invoiceNumber", invoiceOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-numeric-${invoiceOrder} mr-1`}
                    ></i>
                    {props.t("Invoice Details")}
                  </th>
                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      dateOrder === "ascending"
                        ? dateOrderOps("descending")
                        : dateOrderOps("ascending");
                      sortBy("dateorg", dateOrder);
                    }}
                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-numeric-${dateOrder} mr-1`}
                    ></i>
                    {props.t("Date")}
                  </th>
                  <th
                    onClick={() => {
                      nameOrder === "ascending"
                        ? nameOrderOps("descending")
                        : nameOrderOps("ascending");
                      sortBy("childName", nameOrder);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-alphabetical-${nameOrder} mr-1`}
                    ></i>
                    {props.t("Student Details")}
                  </th>
                  {/* <th>{props.t('Invoice Title')}</th> */}
                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      statusOrder === "ascending"
                        ? statusOrderOps("descending")
                        : statusOrderOps("ascending");
                      sortBy("status", statusOrder);
                    }}
                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-alphabetical-${statusOrder} mr-1`}
                    ></i>
                    {props.t("Status")}
                  </th>
                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      payableOrder === "ascending"
                        ? payableOrderOps("descending")
                        : payableOrderOps("ascending");
                      sortBy("totalAmount", payableOrder);
                    }}
                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-numeric-${payableOrder} mr-1`}
                    ></i>
                    {props.t("Payable")}(
                    <span style={{ fontFamily: "Arial, sans-serif" }}>
                      {props.currency}
                    </span>
                    )
                  </th>
                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      receivedOrder === "ascending"
                        ? receivedOrderOps("descending")
                        : receivedOrderOps("ascending");
                      sortBy("paidAmount", receivedOrder);
                    }}
                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-numeric-${receivedOrder} mr-1`}
                    ></i>
                    {props.t("Received")}(
                    <span style={{ fontFamily: "Arial, sans-serif" }}>
                      {props.currency}
                    </span>
                    )
                  </th>
                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      balanceOrder === "ascending"
                        ? balanceOrderOps("descending")
                        : balanceOrderOps("ascending");
                      sortBy("balanceAmount", balanceOrder);
                    }}

                  >
                    <i
                      className="bx bxs-sort-alt"
                    // className={`mdi mdi-18px mdi-order-numeric-${balanceOrder} mr-1`}
                    ></i>
                    {props.t("Balance")}(
                    <span style={{ fontFamily: "Arial, sans-serif" }}>
                      {props.currency}
                    </span>
                    )
                  </th>
                  <th>{props.t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {showLoader && (
                  <tr>
                    <td colSpan="10" className="pt-4 text-center">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                        Loading...{" "}
                      </Link>
                    </td>
                  </tr>
                )}
                {invoices.length === 0 && !showLoader && (
                  <tr>
                    <td colSpan="10" className="pt-4">
                      <h3 className="text-center text-info">
                        {props.t("No invoices found for the filter.")}
                      </h3>
                    </td>
                  </tr>
                )}
                {invoices.map((val, key) => (
                  <tr key={key}>
                    {/* <td></td> */}
                    <td>
                      <p
                        data-toggle="tooltip"
                        data-placement="top"
                        title={val.invoiceTitle}
                      >
                        {val.invoiceTitle.slice(0, 20)}
                        {val.invoiceTitle.length > 20 ? "..." : ""}
                      </p>
                      <small style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowEditInvoiceNumber(val);
                        }}>
                        {val.invoiceNumber}<i className="text-primary">{" ✎"}</i>
                      </small>
                    </td>
                    <td>{val.date}</td>
                    {/* <td>{val.childName}</td> */}
                    <td
                      data-toggle="tooltip"
                      data-placement="top"
                      title={val.childName}
                    >
                      {val.childName ? val.childName.slice(0, 20) : ""}
                      {val.childName
                        ? val.childName.length > 20
                          ? "..."
                          : ""
                        : ""}
                      {val.programId && (
                        <p className="text-warning">
                          <small>{props.t("program")}</small>
                        </p>
                      )}
                    </td>
                    {/* <td></td> */}
                    <td>
                      <p className={`text-${val.status.color}`}>
                        {val.status.stat}
                      </p>
                      {val.showPendingVerify && (
                        <p
                          className="text-danger m-o p-0"
                          style={{ fontSize: "10px", cursor: "pointer" }}
                          onClick={() => tog_triansaction(val.id)}
                        >
                          {props.t("Not Verified")}
                        </p>
                      )}
                    </td>
                    <td>
                      <a
                        className="float-right"
                        onClick={() => tog_invoice(val.id)}
                      >
                        {parseFloat(val.totalAmount).toFixed(2)}
                      </a>
                    </td>
                    <td>
                      <a
                        className="float-right"
                        onClick={() => tog_triansaction(val.id)}
                      >
                        {parseFloat(val.paidAmount).toFixed(2)}
                      </a>
                    </td>
                    <td>
                      <a className="float-right">
                        {parseFloat(val.balanceAmount).toFixed(2)}
                      </a>
                    </td>
                    <td>
                      <Dropdown
                        isOpen={val.isActionActive}
                        toggle={() => activeDropDown(val.id)}
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-secondary"
                        >
                          {val.paidStatus === 0 && props.t("Receive Payment")}
                          {val.paidStatus > 0 && props.t("Print Receipt")}{" "}
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* //use a instade of a */}
                          {val.paidStatus !== 1 && (
                            <DropdownItem
                              onClick={() => tog_receivePayment(val)}
                            >
                              <Label className="text-dark">
                                {props.t("Receive Payment")}
                              </Label>
                            </DropdownItem>
                          )}
                          {val.paidStatus !== 0 && (
                            <DropdownItem>
                              <Link
                                className="text-dark"
                                to={`/invoice/${val.id}`}
                                target="_blank"
                              >
                                {props.t("Print Receipt")}
                              </Link>
                            </DropdownItem>
                          )}
                          {val.paidStatus === 0 && (
                            <DropdownItem>
                              <Link
                                className="text-dark"
                                to={`/invoice/${val.id}`}
                                target="_blank"
                              >
                                {props.t("Print Invoice")}
                              </Link>
                            </DropdownItem>
                          )}
                          {val.paidStatus !== 0 && (
                            <DropdownItem
                              onClick={() => tog_triansaction(val.id)}
                            >
                              <a className="text-dark">
                                {props.t("View Transactions")}
                              </a>
                            </DropdownItem>
                          )}
                          {val.paidStatus !== 1 && (
                            <DropdownItem
                              onClick={() => {
                                // sendNotifyToParent(val.id, val.childId);
                                setInvoiceObject(val);
                                setPaymentSharePopup(true);
                              }}
                            >
                              <a className="text-dark">
                                {props.t("Payment Link")}
                              </a>
                            </DropdownItem>
                          )}
                          {val.paidStatus !== 1 && (
                            <DropdownItem
                              onClick={() => {
                                sendNotifyToParent(val.id, val.childId);
                              }}
                            >
                              <a className="text-dark">
                                {props.t("Notify Parents")}
                              </a>
                            </DropdownItem>
                          )}
                          {val.paidAmount === 0 && (
                            <DropdownItem onClick={() => confirmDelete(val.id)}>
                              <a className="text-dark">{props.t("Delete")}</a>
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>{" "}
                    </td>
                  </tr>
                ))}
                {invoices.length === 1 && (
                  <tr>
                    <td colSpan="8" className="mb-3"></td>
                    <td colSpan="8"></td>
                  </tr>
                )}
              </tbody>
            </table>
            <Row className="mt-1">
              <Col>
                {props.t("Show Entries")}
                <select
                  className="custom-select ml-1"
                  value={entryCount}
                  style={{ width: "15%" }}
                  onChange={(e) => {
                    entryCountOps(e.target.value);
                  }}
                >
                  {entries.map((val, key) => (
                    <option key={key} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-end"
                >
                  <PaginationItem disabled={currentPage === "1"}>
                    <PaginationLink
                      onClick={() => currentPageOps(+currentPage - 1 + "")}
                      href="#"
                      tabIndex="-1"
                    >
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  {paginationList.map((val, key) => (
                    <PaginationItem active={currentPage === val} key={key}>
                      <PaginationLink
                        onClick={() => currentPageOps(val)}
                        href="#"
                      >
                        {val}{" "}
                        {currentPage === val && (
                          <span className="sr-only">(current)</span>
                        )}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={currentPage === paginationList.length + ""}
                  >
                    <PaginationLink
                      href="#"
                      onClick={() => currentPageOps(+currentPage + 1 + "")}
                    >
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>

      {showEditInvoiceNumber && <Modal
        isOpen={showEditInvoiceNumber}
      >
        <ModalHeader tag="h4">
          {props.t('Edit Invoice Number')}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={(e, v) => {
            if (showEditInvoiceNumber.invoiceNumber === v.invoicenumber) {
              setShowEditInvoiceNumber(undefined);
            } else {
              let invoiceObj = showEditInvoiceNumber.Obj;
              invoiceObj.set("invoiceNumber", v.invoicenumber);
              props.saveInvoice(invoiceObj);
            }
          }}>
            <Row form>
              <Col className="col-12">
                <AvField
                  name="invoicenumber"
                  type="text"
                  placeholder={props.t('Enter invoice number')}
                  value={showEditInvoiceNumber.invoiceNumber}
                  required
                  errorMessage={props.t('Invoice number is mandatory')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {props.invoiceEditLoader ? <div className="text-right">
                  <button className="btn btn-success save-event">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>
                    {props.t('Updating')}
                  </button>
                </div> : <div className="text-right">
                  <button type="button" className="btn btn-light m-1" onClick={() => {
                    setShowEditInvoiceNumber(undefined);
                  }}>{props.t('Close')}</button>
                  <button type="submit" className="btn btn-success save-event m-1">
                    {props.t('Update')}
                  </button>
                </div>}
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>}
      {invoiceObject && <PaymentLinkSharePopup
        paymentSharePopup={paymentSharePopup}
        closePaymentSharePopup={() => {
          setPaymentSharePopup(!paymentSharePopup);
        }}
        invoiceObject={invoiceObject}
        sendNotifyToParent={sendNotifyToParent}
      />}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { kids, admissionKids, allDeletedKids } = state.Kid;
  const { groupmaps } = state.GroupMap;
  const {
    invoices,
    showLoader,
    successResCreateInvoice,
    deleteInProgress,
    deleteSuccess,
    invoiceCount,
    invoiceEditLoader,
  } = state.Invoice;
  const { pendingVerifyMT } = state.MoneyTransactions;
  return {
    kids,
    groupmaps,
    admissionKids,
    allDeletedKids,
    invoices,
    showLoader,
    successResCreateInvoice,
    pendingVerifyMT,
    deleteInProgress,
    deleteSuccess,
    invoiceCount,
    invoiceEditLoader,
  };
};

export default connect(mapStatetoProps, {
  getGroupMapsForGroupId,
  getInvoicesForFilters,
  getInvoicesCountForFilters,
  getVerifiedStatusForInvoices,
  deleteSelectedInvoice,
  notifyPaymentToParent,
  saveInvoice,
})(withRouter(withNamespaces()(AllInvoice)));
