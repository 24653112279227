import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Moment from "moment";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import {
  getTeachersForSchool,
  getClassroomsForSchoolId,
  getAllKids,
  getParentsForFamilyIds,
} from "../../store/actions";
import StudentExitReport from "../reports/franchise/StudentExitReport"
import DaywiseSummary from './DaywiseSummaryReport'
import NewAdmissionReport from "../reports/franchise/NewAdmissionReport"
import CctvReport from "./CctvReport"
import DairyReport from "./DairyReport";

const SchoolReport = (props) => {
  const [reportType, reportTypeOps] = useState("1");
  const [gotReport, gotReportOps] = useState(false);
  const [allData, allDataOps] = useState([]);
  const [requested, requestedOps] = useState(false);
  //dec
  const [allClassRooms, allClassRoomsOps] = useState([]);
  useEffect(() => {
    if (props.schoolId && requested) {
      props.getClassroomsForSchoolId(props.schoolId);
    }
  }, [props.schoolId,requested]);
  const getAttendanceReport = () => {
    gotReportOps(false);
    requestedOps(true);
    if (reportType === "1") {
      props.getTeachersForSchool(props.schoolId);
    } else if (reportType === "2") {
      props.getAllKids(props.schoolId);
    }
  };
  useEffect(() => {
    if ((props.teacherReport || props.kidReport) && requested) {
      gotReportOps(true);
    } else {
      gotReportOps(false);
    }
  }, [props.teacherReport, props.kidReport]);
  useEffect(() => {
    let tempClass = [];
    for (const val of props.classrooms) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.Name;
      temp.type = val.attributes.classroomType; //type 2 daycare type 1/undefine class
      temp.obj = val;
      tempClass.push(temp);
    }
    allClassRoomsOps(tempClass);
  }, [props.classrooms,]);
  useEffect(() => {
    let tempTeacher = [];
    if (!requested) {
      return;
    }
    for (const val of props.teachers) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.name = val.attributes.Name;
      if (val.attributes.designation) {
        temp.designation = val.attributes.designation;
      } else {
        if (val.attributes.Role == 1) {
          temp.designation = "Teacher";
        } else if (val.attributes.Role == 2) {
          temp.designation = "Center Head";
        } else if (val.attributes.Role == 3) {
          temp.designation = "Director";
        }
      }
      temp.phoneNumber = val.attributes.PhoneNumber;
      temp.email = val.attributes.EMail;
      temp.classList = "";
      if (val.attributes.Role === 3 || val.attributes.Role === 2) {
        temp.classList = "All";
      } else if (
        val.attributes.ClassRooms &&
        val.attributes.ClassRooms.length > 0
      ) {
        for (const list of val.attributes.ClassRooms) {
          for (const aList of allClassRooms) {
            if (aList.id === list) {
              temp.classList += aList.name + ", ";
              break;
            }
          }
        }
      } else {
        temp.classList = "--";
      }
      temp.employerCode = val.attributes.empCode;
      temp.dateOfJoin = val.attributes.doj
        ? getDateInDDMMYYYY(val.attributes.doj)
        : "";
      temp.gender = val.attributes.Gender ?? ""
      temp.emergencyContact = val.attributes.emergencyContact ?? ""
      tempTeacher.push(temp);
    }
    allDataOps(tempTeacher);
    // gotReportOps(true);
  }, [props.teachers]);
  useEffect(() => {
    if (!requested) {
      return;
    }
    let tempList = [];
    let familyIds = [];
    for (const val of props.kids) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.name = val.attributes.Name;
      temp.gender = val.attributes.Gender;
      temp.dob = val.attributes.DateOfBirth
        ? getDateInDDMMYYYY(val.attributes.DateOfBirth)
        : "";
      temp.admissionNumber = val.attributes.admissionNumber;
      if (val.attributes.ClassRoomID) {
        for (const classRoom of allClassRooms) {
          if (classRoom.id === val.attributes.ClassRoomID) {
            temp.className = classRoom.name;
          }
        }
      } else {
        temp.className = "Admission";
      }
      temp.doa = getDateInDDMMYYYY(val.attributes.createdAt);
      temp.familyId = val.attributes.FamilyID;
      familyIds.push(val.attributes.FamilyID);

      if (props.parents) {
        for (const parent of props.parents) {
          if (parent.attributes.FamilyID === val.attributes.FamilyID)
            if (parent.attributes.Relation === "Mother") {
              temp.motherName = parent.attributes.Name;
              temp.motherPhoneNumber = parent.attributes.PhoneNumber;
              temp.motherEmail = parent.attributes.EMail;
              temp.motherObj = parent;
            } else if (parent.attributes.Relation === "Father") {
              temp.fatherName = parent.attributes.Name;
              temp.fatherPhoneNummber = parent.attributes.PhoneNumber;
              temp.fatherEmail = parent.attributes.EMail;
              temp.fatherObj = parent;
            }
        }
      } else {
        temp.motherName = "";
        temp.motherPhoneNumber = "";
        temp.motherEmail = "";
        temp.fatherName = "";
        temp.fatherPhoneNummber = "";
        temp.fatherEmail = "";
        // console.log("no paretns");
      }
      tempList.push(temp);
    }
    allDataOps(tempList);
    if (familyIds && familyIds.length > 0 && requested) {
      props.getParentsForFamilyIds(familyIds);
    }
    // gotReportOps(true);
  }, [props.kids]);
  useEffect(() => {
    let tempData = Object.assign([], allData);
    if (tempData && tempData.length>0 && props.parents && props.parents.length > 0) {
      
      for (const parent of props.parents) {
        for (const kid of tempData) {
          if (kid.familyId === parent.attributes.FamilyID) {
            if (parent.attributes.Relation === "Mother") {
              kid.motherName = parent.attributes.Name;
              kid.motherPhoneNumber = parent.attributes.PhoneNumber;
              kid.motherEmail = parent.attributes.EMail;
              kid.motherObj = parent;
            } else {
              kid.fatherName = parent.attributes.Name;
              kid.fatherPhoneNummber = parent.attributes.PhoneNumber;
              kid.fatherEmail = parent.attributes.EMail;
              kid.fatherObj = parent;
            }
          }
        }
      }
      allDataOps(tempData);
    }
  }, [props.parents]);
  const getDateInDDMMYYYY = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  const downloadTheReport = () => {
    let rawData = allData;
    let finalData = [];
    let titles = [];
    let fileName = "";
    let sheetName = "";
    if (reportType === "1") {
      sheetName = "All Staff";
      fileName = "All Staff.xlsx";
      finalData.push([`Report Type: All Staff`]);
      finalData.push([]);
      titles = [
        "Name",
        "Gender",
        "Designation",
        "Phone Number",
        "EMail",
        "Classes",
        "Employer Code",
        "Date Of Join",
        "Date Of Birth",
        "Emergency Contact"
      ];
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.name);
        temp.push(val.gender);
        temp.push(val.designation);
        temp.push(val.phoneNumber);
        temp.push(val.email);
        temp.push(val.classList);
        temp.push(val.employerCode);
        temp.push(val.dateOfJoin);
        temp.push(val.dob);
        temp.push(val.emergencyContact);
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    } else if (reportType === "2") {
      sheetName = "All Students";
      fileName = "All Students.xlsx";
      finalData.push([`Report Type: All Students`]);
      finalData.push([]);
      titles = [
        "Name",
        "Class",
        "Gender",
        "DOB",
        "Admission Number",
        "Date Of Admission",
        "Father Name",
        "Father Email",
        "Father PhoneNumber",
        "Mother Name",
        "Mother Email",
        "Mother PhoneNumber",
      ];
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.name);
        temp.push(val.className);
        temp.push(val.gender);
        temp.push(val.dob);
        temp.push(val.admissionNumber);
        temp.push(val.doa);
        temp.push(val.fatherName);
        temp.push(val.fatherEmail);
        temp.push(val.fatherPhoneNummber);
        temp.push(val.motherName);
        temp.push(val.motherEmail);
        temp.push(val.motherPhoneNumber);
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    }
  };
  return (
    <React.Fragment>
      <CardTitle>{props.t("School Report")}</CardTitle>
      <CardSubtitle className="mb-3">
        {props.t("Select filter to generate the report")}
      </CardSubtitle>
      <Row className="bg-light">
        <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>{props.t("Report Type")} :</Label>
            <select
              className="form-control "
              value={reportType}
              onChange={(e) => {
                reportTypeOps(e.target.value);
                gotReportOps(false);
              }}
            >
              <option value={1}>{props.t("Staff")}</option>
              <option value={2}>{props.t("Student")}</option>
              <option value={3}>{props.t("New Admission Report")}</option>
              <option value={4}>{props.t("Student Exit Report")}</option>
              <option value={5}>{props.t("CCTV Report")}</option>
              <option value={6}>{props.t("DayCare Dairy Report")}</option>
              <option value={7}>{props.t("Daywise Summary Report")}</option>
            </select>
          </FormGroup>
        </Col>
        {reportType != "3" && reportType != "4" &&  reportType != "5" && reportType != "6" && <Col md="2" xs="12" xl="2" lg="2">
          <div className="mt-2">
            <div className="mt-3">
              <button
                className="btn btn-primary mt-4 mr-2"
                onClick={getAttendanceReport}
              >
                {props.t("Generate")}
              </button>
            </div>
          </div>
        </Col>}
      </Row>
      {gotReport && allData.length > 0 && (
        <Row>
          <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
            <button
              className="btn btn-info float-right"
              onClick={downloadTheReport}
            >
              <i className="bx bxs-download mr-1" />
              {props.t("Download")}
            </button>
          </Col>
        </Row>
      )}
      {gotReport && reportType != "3" && reportType != "4" && (
        <Row>
          {allData.length > 0 && reportType === "1" && (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{props.t("Name")}</th>
                    <th>{props.t("Designation")}</th>
                    <th>{props.t("Phone Number")}</th>
                    <th>{props.t("Email")}</th>
                    <th>{props.t("Class")}</th>
                    <th>{props.t("Gender")}</th>
                    <th>{props.t("Employer Code")}</th>
                    <th>{props.t("Date Of Birth")}</th>
                    <th>{props.t("Date Of Join")}</th>
                    <th>{props.t("Emergency Contact")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((val, key) => (
                    <tr key={key}>
                      <td>{val.name}</td>
                      <td>{val.designation}</td>
                      <td>{val.phoneNumber}</td>
                      <td>{val.email}</td>
                      <td>{val.classList}</td>
                      <td>{val.gender}</td>
                      <td>{val.employerCode}</td>
                      <td>{val.dob}</td>
                      <td>{val.dateOfJoin}</td>
                      <td>{val.emergencyContact}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {allData.length > 0 && reportType === "2" && (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{props.t("Name")}</th>
                    <th>{props.t("Class")}</th>
                    <th>{props.t("Gender")}</th>
                    <th>{props.t("Date Of Birth")}</th>
                    <th>{props.t("Admission Number")}</th>
                    <th>{props.t("Date Of Admission")}</th>
                    <th>{props.t("Father Name")}</th>
                    <th>{props.t("Father Email")}</th>
                    <th>{props.t("Father Phone Number")}</th>
                    <th>{props.t("Mother Name")}</th>
                    <th>{props.t("Mother Email")}</th>
                    <th>{props.t("Mother Phone Number")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((val, key) => (
                    <tr key={key}>
                      <td>{val.name}</td>
                      <td>{val.className}</td>
                      <td>{val.gender}</td>
                      <td>{val.dob}</td>
                      <td>{val.admissionNumber}</td>
                      <td>{val.doa}</td>
                      <td>{val.fatherName}</td>
                      <td>{val.fatherEmail}</td>
                      <td>{val.fatherPhoneNummber}</td>
                      <td>{val.motherName}</td>
                      <td>{val.motherEmail}</td>
                      <td>{val.motherPhoneNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {allData.length === 0 && (
            <Col>
              <h3 className="text-center text-info mt-3">
                {props.t("No Report Found For The Filter")}
              </h3>
            </Col>
          )}
        </Row>
      )}
      {reportType == "3" && <NewAdmissionReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "4" && <StudentExitReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "5" && <CctvReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "6" && <DairyReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "7" && <DaywiseSummary
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { teachers, teacherReport } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids, kidReport } = state.Kid;
  const { parents } = state.Parent;
  return { teachers, classrooms, kids, parents, teacherReport, kidReport };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getTeachersForSchool,
      getClassroomsForSchoolId,
      getAllKids,
      getParentsForFamilyIds,
    })(SchoolReport)
  )
);
