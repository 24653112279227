import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Alert,
} from "reactstrap";
import Breadcrumbs from "./Breadcrumbs";
import { withNamespaces } from "react-i18next";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import DatePicker from "react-datepicker";
import toastr from "toastr";
import Parse from "parse";
import Moment from "moment";
import COUNTRY_JSON from '../../assets/other/countries.json'
//database operations
import {
  getUserTeacher,
  //getSchool,
  getClassroomsForSchoolId,
  getGroupsForSchoolId,
  getProgramsForSchoolId,
  getAdmissionKidsForSchool,
  getGroupMapsForGroupId,
  getFeeTemplateForSchoolId,
  getFeeTemplateItemsForTemplateId,
  createInvoiceWithFunction,
  getInvoiceItemForInvoiceId,
  getPaymentOptionsForSchool,
  setSelecetdSchool,
  setInvoiceSuccessComplete,
  getAllKids,
  getAllDeletedKidForSchool,
  getReceiptAddressForSchoolId,
  getFranchise,
  getCorporatePartners,
} from "../../store/actions";

import AllInvoice from "./allInvoice";
import AllTransaction from "./AllTransaction";
import SchoolTemplate from "./SchoolTemplate";
import Select from "react-select";
import RecurringInvoices from "./RecurringInvoices";

import RecurringInvoicePopup from './RecurringInvoicePopup'
import E from "react-script";

const Payment = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/payments/:id",
    exact: true,
    strict: false,
  });
  const schoolId = match.params.id;
  useEffect(() => {
    if (Parse.User.current()) {
      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId);
      }
    }
  }, [Parse.User.current()]);


  const [activeTab, activeTabOps] = useState("1");
  const [currency, currencyOps] = useState("₹");
  const [classList, classListOps] = useState([]);
  const [groupList, groupListOps] = useState([]);
  const [programList, programListOps] = useState([]);
  const [modal_standard, setmodal_standard] = useState(false);
  //const [schoolName, schoolNameOps] = useState("");
  const [activeFilterBy, activeFilterByOps] = useState("");
  const [filterByList, filterByListOps] = useState([]);
  const [kidsList, kidListOps] = useState([]);
  const [allKid, allKidOps] = useState();
  const [feeTemplate, feeTemplateOps] = useState([]);
  const [activeFilterTo, activeFilterToOps] = useState("all");
  const [activeFilterKid, activeFilterKidOps] = useState([]);
  const [invoiceItemList, invoiceItemListOps] = useState([]);
  const [activeTemplate, activeTemplateOps] = useState("");
  const [newInvoiceTotal, newInvoiceTotalOps] = useState(0);
  const [newInvoiceTax, setInvoiceTax] = useState(0);
  const [paymentTitle, paymentTitleOps] = useState("");
  const [activeYear, activeYearOps] = useState("2024-25");

  const [paymentTitleError, paymentTitleErrorOps] = useState("");
  const [selectedNumberOfKids, selectedNumberOfKidsOps] = useState(0);
  const [kidOptions, kidOptionsOps] = useState([]);
  const filterListAllInvoices = ["class", "program", "admission", "group"];
  const filterList = ["class", "admission", "group"];
  const [list, setList] = useState(["2024-25", "2023-24", "2022-23", "2021-22", "2020-21",]);
  const [selectlist, setSelectlist] = useState(["2024-25", "2023-24", "2022-23"])
  const [childrenError, childrenErrorOps] = useState("");
  const [amountError, amountErrorOps] = useState("");
  const [paymentOptions, paymentOptionsOps] = useState([]);
  const [activeAcc, activeAccOps] = useState("");
  // const [toggleId, toggleIdOps] = useState();
  const [teacherPaymenrWrite, teacherPaymenrWriteOps] = useState(false);
  const [paymentAdmin, paymentAdminOps] = useState(false);
  const [receiptAddress, setReceiptAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  // const [addressButton, setAddressButton] = useState(false);

  const [taxable, setTaxable] = useState(true);
  const [taxError, setTaxError] = useState(false);

  const [showRoyalty, setShowRoyalty] = useState(false);
  const [completeDisable, setCompleteDisable] = useState(false);
  const [royaltyAmount, setRoyaltyAmount] = useState(0);

  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [recurringObject, setRecurringObject] = useState(false);

  const [showCorporatePartners, setShowCorporatePartners] = useState(false);
  const [revenueStartDate, setRevenueStartDate] = useState(undefined);
  const [revenueEndDate, setRevenueEndDate] = useState(undefined);;
  const [templetRestiction, setTempletRestiction] = useState(false);
  const [templetRestictionEdit, setTempletRestictionEdit] = useState(false);


  const [showUI, setShowUI] = useState(false);

  useEffect(() => {
    if (props.userTeacher) {
      if (props.userTeacher.attributes.featurePermissions &&
        props.userTeacher.attributes.featurePermissions.length > 3 &&
        props.userTeacher.attributes.featurePermissions[3] === 2) {
        teacherPaymenrWriteOps(true);
      }
      if (props.userTeacher.attributes.featurePermissions &&
        props.userTeacher.attributes.featurePermissions.length > 11 &&
        props.userTeacher.attributes.featurePermissions[11] === 1) {
        paymentAdminOps(true);
      }
      if (Parse.User.current().attributes.isSuperUser) {
        paymentAdminOps(true);
        teacherPaymenrWriteOps(true);
      }
    }
  }, [props.userTeacher]);
  useEffect(() => {
    // props.getSchool(schoolId, true);
    if (showUI) {
      props.getClassroomsForSchoolId(schoolId);
      props.getAllKids(schoolId);
      props.getAdmissionKidsForSchool(schoolId);;
      props.getAllDeletedKidForSchool(schoolId);
      props.getGroupsForSchoolId(schoolId);
      props.getProgramsForSchoolId(schoolId);
      props.getFeeTemplateForSchoolId(schoolId);
      props.getPaymentOptionsForSchool(schoolId);
      props.getReceiptAddressForSchoolId(schoolId);
    } else {
      setTimeout(myFunction, 2000);
    }
  }, [schoolId,showUI]);

  const myFunction = () => {
    setShowUI(true);
  }

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
    if (props.selectedSchool) {
      // schoolNameOps(props.selectedSchool.attributes.Name);

      if (props.selectedSchool.attributes.countryCode != "IN") {
        for (const cc of COUNTRY_JSON) {
          if (cc.code == props.selectedSchool.attributes.countryCode) {
            currencyOps(cc.currency.currencySymbol);
            break;
          }
        }

        if (props.selectedSchool.attributes.countryCode == "LK") {
          setList(["2025", "2024", "2023",])
          setSelectlist(["2025", "2024", "2023"])
          activeYearOps("2023")
        }
      }
    }

    if (props.selectedSchool &&
      (props.selectedSchool.attributes.franchiseId == "sP8TeR7BSG"
        || props.selectedSchool.attributes.franchiseId == "AwiwOAmsEf")) {
      setTempletRestiction(true);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[2] > 0) {
      setShowRoyalty(true);
      if (props.franchise.attributes.features[2] == 2) {
        setCompleteDisable(true)
      } else {
        setCompleteDisable(false)
      }
    } else {
      setShowRoyalty(false);
      setCompleteDisable(false)
    }
    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[4] > 0) {
      setShowCorporatePartners(true);
      props.getCorporatePartners(props.franchise.id, "franchiseId")

    } else {
      setTempletRestiction(false);
      setTempletRestictionEdit(false);
      setShowCorporatePartners(false);
    }
  }, [props.franchise]);

  useEffect(() => {
    if (props.classrooms) {
      let tempClasses = [];
      props.classrooms.map((val, key) => {
        let temp = {};
        temp.obj = val;
        temp.id = val.id;
        temp.name = val.attributes.Name;
        tempClasses.push(temp);
      });
      classListOps(tempClasses);
    }
  }, [props.classrooms]);
  useEffect(() => {
    if (props.groups) {
      let groupTemp = [];
      props.groups.map((val, key) => {
        let temp = {};
        temp.obj = val;
        temp.id = val.id;
        temp.name = val.attributes.name;
        groupTemp.push(temp);
      });
      groupListOps(groupTemp);
    }
  }, [props.groups]);
  useEffect(() => {
    if (props.programs) {
      let programTemp = [];
      props.programs.map((val, key) => {
        let temp = [];
        temp.Obj = val;
        temp.id = val.id;
        temp.name = val.attributes.name;
        programTemp.push(temp);
      });
      programListOps(programTemp);
    }
  }, [props.programs]);

  useEffect(() => {
    if (props.feeTemplates) {
      let tempTemplate = [];
      for (let val of props.feeTemplates) {
        let temp = {};
        temp.Obj = val;
        temp.id = val.id;
        temp.title = val.attributes.name;
        if (!val.attributes.isDisabled) {
          tempTemplate.push(temp);
        }
      }
      if (templetRestiction) {

      } else {
        feeTemplateOps(tempTemplate);
      }

    }
  }, [props.feeTemplates]);

  useEffect(() => {
    if (props.paymentOptionsForSchool.length > 1) {
      let temp = [];
      for (const val of props.paymentOptionsForSchool) {
        let tempIn = {};
        tempIn.id = val.id;
        tempIn.Obj = val;
        tempIn.accName = val.attributes.bankName;
        tempIn.accNum = val.attributes.accNo.toString();
        tempIn.x = "";
        for (let j = 0; j < tempIn.accNum.length - 5; j++) {
          tempIn.x += "X";
        }
        tempIn.accNum1 = tempIn.accNum.substr(tempIn.accNum.length - 5);
        tempIn.showAccNo = tempIn.x + tempIn.accNum1;
        tempIn.displayName = tempIn.accName + "(" + tempIn.showAccNo + ")";
        temp.push(tempIn);
      }
      paymentOptionsOps(temp);
      activeAccOps(temp[0]);
    }
  }, [props.paymentOptionsForSchool]);

  useEffect(() => {
    updateAddress();
  }, [props.receiptAddres]);

  const updateAddress = () => {
    if (props.receiptAddres.length > 0) {
      let temp = [];
      let schoolAddress = {
        id: "schoolId",
        name: "School Address"
      }
      temp.push(schoolAddress)
      for (const val of props.receiptAddres) {
        let tempIn = {};
        tempIn.id = val.id;
        tempIn.object = val;
        tempIn.name = val.attributes.name;

        temp.push(tempIn);
      }
      setSelectedAddress(temp[0]);
      setReceiptAddress(temp);

    }
  }

  useEffect(() => {
    processKidData(props.kids);
  }, [props.kids]);


  function tog_standard() {
    newInvoiceTotalOps(0);
    setInvoiceTax(0)
    setTaxable(false);
    setTaxError(false);
    updateAddress();
    invoiceItemListOps([]);
    if (!modal_standard) {
      toShowAllKids();
      activeFilterByOps("class");
      activeFilterToOps("all");
      // activeFilterKidOps("all");
      paymentTitleOps("");
      activeTemplateOps("");
      activeYearOps("2024-25");
      if (props.selectedSchool.attributes.countryCode == "LK") {
        activeYearOps("2023");
      }
      invoiceItemListOps([]);
      // activeFilterKidOps([{ label: "@All", value: 0 }]);
      activeFilterKidOps([]);
      childrenErrorOps("");
      paymentTitleErrorOps("");
    }
    setmodal_standard(!modal_standard);
    removeBodyCss();
    activeFilterByOps("class");
    paymentTitleOps("");
    paymentTitleErrorOps("");
    setRecurringObject(undefined);
    setShowRecurringModal(false);
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  useEffect(() => {
    switch (activeFilterBy) {
      case "class":
        filterByListOps(classList);
        toShowAllKids();

        break;
      case "group":
        filterByListOps(groupList);
        toShowAllKids();

        break;
      case "program":
        filterByListOps(programList);
        toShowAllKids();

        break;
      case "admission":
        filterByListOps([]);
        processKidData(props.admissionKids);
        break;
      default:
        activeFilterByOps("class");
    }
    activeFilterToOps("all");
  }, [activeFilterBy, classList]);


  const toShowAllKids = () => {
    processKidData(props.kids);
  };




  useEffect(() => {
    if (activeFilterTo === "all") {
      toShowAllKids();
      // filterByListOps(props.classList)
      let temp = activeFilterBy;
      activeFilterByOps("");
      activeFilterByOps(temp);
      if (!activeFilterTo) {
        activeFilterByOps("class");
      }
    } else if (activeFilterBy === "class") {

      let classKids = props.kids && props.kids.filter(
        (kid) => (kid.attributes.ClassRoomID === activeFilterTo || kid.attributes.daycareClassId === activeFilterTo));

      processKidData(classKids);

    } else if (activeFilterBy === "group") {
      props.getGroupMapsForGroupId(activeFilterTo);
    } else if (activeFilterBy === "program") {
      programList.map((val, key) => {
        if (val.id === activeFilterTo) {
          props.getGroupMapsForGroupId(val.Obj.attributes.groupId);
          activeTemplateOps(val.Obj.attributes.templateId);
          paymentTitleOps(val.name);
        }
      });
    }
  }, [activeFilterTo]);



  const processKidData = (kids) => {
    if (kids) {
      let kidsTemp = [];
      let allKid = [];
      let allIds = [];
      kids.map((val, key) => {
        let temp = {};
        temp.Obj = val;
        temp.id = val.id;
        allIds.push(val.id);
        temp.name = val.attributes.Name;
        allKid.push(temp);
        kidsTemp.push(temp);
      });
      kidListOps(kidsTemp);
      // console.log(kidsList);
      if (activeFilterBy === "class" && activeFilterTo === "all") {
        allKidOps(kidsTemp);
      }
    }
  };
  const addAnItemToInvoice = () => {
    let tempItems = Object.assign([], invoiceItemList);
    let temp = {};
    temp.itemDescription = `Item ${invoiceItemList.length + 1}`;
    temp.dueDate = new Date();
    temp.slno = tempItems.length;
    temp.amount = 0;
    temp.discount = 0;
    temp.discountType = "1";
    temp.discountRemark = "";
    temp.discountDisabled = true;
    temp.totalAmount = 0;
    temp.tax = undefined;
    if (templetRestiction) {
      temp.canEdit = true;
    } else {
      temp.canEdit = false;
    }
    tempItems.push(temp);
    invoiceItemListOps(tempItems);
  };
  const removeItemFromNewInvoice = (key) => {
    let tempItems = Object.assign([], invoiceItemList);
    let totalAmount = 0;
    let totalTax = 0;
    let totalRoyalty = 0;
    tempItems.splice(key, 1);
    for (let i in tempItems) {
      tempItems[i].totalAmount =
        tempItems[i].discountType === "1"
          ? tempItems[i].amount - tempItems[i].discount
          : tempItems[i].amount -
          (tempItems[i].amount * tempItems[i].discount) / 100;
      tempItems[i].totalAmount = roundToTwo(tempItems[i].totalAmount);
      totalAmount += tempItems[i].totalAmount;
      if (tempItems[i].tax > 0) {
        let tTax = roundToTwo(tempItems[i].totalAmount * 0.01 * tempItems[i].tax);
        tempItems[i].taxAmount = tTax;
        totalTax = totalTax + tTax
        tempItems[i].totalAmount = tempItems[i].totalAmount + tTax
      }

      let royalty = tempItems[i].royalty;
      if (royalty > 0) {
        let royaltyAmount = (tempItems[i].totalAmount * royalty) / 100
        tempItems[i].royaltyAmount = royaltyAmount;
        totalRoyalty += royaltyAmount;
      } else {
        tempItems[i].royaltyAmount = 0;
      }
    }
    setRoyaltyAmount(totalRoyalty);
    setInvoiceTax(totalTax);
    newInvoiceTotalOps(totalAmount);
    invoiceItemListOps(tempItems);
  };
  const updateInvoiceData = (key, val, updatedValue) => {
    let tempItems = Object.assign([], invoiceItemList);
    let totalAmount = 0;
    let totalTax = 0;
    let totalRoyalty = 0;
    tempItems[key][val] = updatedValue;
    for (let i in tempItems) {
      tempItems[i].totalAmount =
        tempItems[i].discountType === "1"
          ? tempItems[i].amount - tempItems[i].discount
          : tempItems[i].amount -
          (tempItems[i].amount * tempItems[i].discount) / 100;
      tempItems[i].totalAmount = roundToTwo(tempItems[i].totalAmount);
      if (tempItems[i].tax > 0) {
        let tTax = roundToTwo(tempItems[i].totalAmount * 0.01 * tempItems[i].tax);
        tempItems[i].taxAmount = tTax
        totalTax = totalTax + tTax
        tempItems[i].totalAmount = tempItems[i].totalAmount + tTax
      } else {
        tempItems[i].taxAmount = 0;
      }

      if (tempItems[i].royalty > 0) {
        tempItems[i].royalty = parseFloat(tempItems[i].royalty)
        tempItems[i].royaltyAmount = (tempItems[i].totalAmount * tempItems[i].royalty) / 100;
        totalRoyalty += tempItems[i].royaltyAmount;
      } else {
        tempItems[i].royaltyAmount = 0;
      }

      totalAmount += tempItems[i].totalAmount;
      tempItems[i].discountDisabled = tempItems[i].discount > 0 ? true : false;
      tempItems[i].discountRemark = tempItems[i].discountDisabled
        ? tempItems[i].discountRemark
        : "";
    }
    if (taxError) {
      setTaxError(false);
    }
    setRoyaltyAmount(totalRoyalty);
    setInvoiceTax(totalTax);
    newInvoiceTotalOps(totalAmount);
    invoiceItemListOps(tempItems);
    if (totalAmount > 0) {
      amountErrorOps("");
    } else {
      amountErrorOps("Amount cannot be zero");
    }
  };
  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  }

  useEffect(() => {
    if (activeTemplate !== "") {
      props.getFeeTemplateItemsForTemplateId(activeTemplate);
    } else {
      let tempItems = [];
      let totalAmount = 0;
      setInvoiceTax(0);
      newInvoiceTotalOps(totalAmount);
      invoiceItemListOps(tempItems);
    }
  }, [activeTemplate]);
  useEffect(() => {
    if (modal_standard && activeTemplate && props.feeTemplateItems) {
      let tempItems = [];
      let totalAmount = 0;
      let totalTax = 0;
      let totalRoyalty = 0;
      for (let val of props.feeTemplateItems) {
        let temp = {};
        temp.itemDescription = val.attributes.itemDescription;
        temp.slno = val.attributes.slno;
        temp.dueDate = val.attributes.dueDate;
        temp.amount = val.attributes.amount;
        temp.tax = val.attributes.tax;
        temp.discount = val.attributes.discount;
        temp.discountType = val.attributes.discountType + "";
        temp.discountRemark = val.attributes.discountRemark;
        temp.discountDisabled = val.attributes.discount > 0;
        temp.totalAmount =
          val.attributes.discountType == 1
            ? val.attributes.amount - val.attributes.discount
            : val.attributes.amount -
            (val.attributes.amount * val.attributes.discount) / 100;
        temp.totalAmount = roundToTwo(temp.totalAmount);
        temp.feeType = val.attributes.feeType;

        temp.hsn = val.attributes.hsn;
        if (temp.tax > 0) {
          let tTax = roundToTwo(temp.totalAmount * 0.01 * temp.tax);
          temp.taxAmount = tTax
          totalTax = totalTax + tTax
          temp.totalAmount = temp.totalAmount + tTax
        }

        temp.royalty = val.attributes.royalty;
        if (temp.royalty > 0) {
          temp.royaltyAmount = (temp.totalAmount * temp.royalty) / 100
          totalRoyalty += temp.royaltyAmount;
        } else {
          temp.royaltyAmount = 0;
        }

        totalAmount += temp.totalAmount;
        tempItems.push(temp);
      }
      setRoyaltyAmount(totalRoyalty);
      setInvoiceTax(totalTax);
      newInvoiceTotalOps(totalAmount);
      invoiceItemListOps(tempItems);
    }
  }, [props.feeTemplateItems]);
  useEffect(() => {
    if (props.successResCreateInvoice && !props.showLoaderCreateInvoice) {
      toastr.success("Invoice Created Successfully", { timeOut: 1500 });
      toShowAllKids();
      props.setInvoiceSuccessComplete();
    }
  }, [props.successResCreateInvoice]);

  const createInvoice = () => {
    let errorC = 0;
    if (!paymentTitle) {
      paymentTitleErrorOps("Please enter the title");
      errorC++;
    } else {
      paymentTitleErrorOps(" ");
    }
    let receipentIds = getAllReceipentForCurrent();
    if (receipentIds.length === 0) {
      errorC++;
      childrenErrorOps("please select student to create invoice");
    } else {
      childrenErrorOps("");
    }

    if (newInvoiceTotal === 0) {
      errorC++;
      amountErrorOps("Amount cannot be zero");
    } else {
      amountErrorOps("");
    }
    if (errorC === 0) {
      let inputParam = {};
      inputParam.amount = newInvoiceTotal;
      inputParam.instituteId = props.selectedSchool.attributes.instituteId;
      inputParam.schoolId = props.selectedSchool.id;
      let tempItems = Object.assign([], invoiceItemList);
      for (let val of tempItems) {
        val.discountType = +val.discountType;
        val.amount = +val.amount;
        val.discount = +val.discount;
      }
      if (royaltyAmount > 0) {
        inputParam.royaltyAmount = royaltyAmount;
        inputParam.isRoyaltyInvoice = true;
      }
      inputParam.items = tempItems;
      inputParam.programId =
        activeFilterBy === "program"
          ? activeFilterTo !== "all"
            ? activeFilterTo
            : null
          : null;
      inputParam.recipientType = 6; //1 - school 5&2 d=for calss 3 group 6 kid/admission
      inputParam.tags = [activeYear]; //2020-21
      inputParam.title = paymentTitle;


      inputParam.recipientIds = receipentIds;
      if (activeAcc) {
        inputParam.paymentOptionId = activeAcc.id;
      }
      if (props.receiptAddres && props.receiptAddres.length > 0 && selectedAddress.id != "schoolId") {
        inputParam.receiptAddressId = selectedAddress.id;
      }

      if (revenueStartDate && revenueEndDate) {
        inputParam.revenueStartDate = revenueStartDate;
        inputParam.revenueEndDate = revenueEndDate;
      }
      if (recurringObject) {

        inputParam.recurringInvoice = true;
        inputParam.recurringInterval = parseInt(recurringObject.selectedIntervel + "");
        let d = new Date();
        let oneDay = 1000 * 60 * 60 * 24;
        let daysIn = oneDay * recurringObject.selectedIntervel
        let nextDate = new Date(d.getTime() + daysIn);
        inputParam.nextInvoiceDate = nextDate;
        inputParam.recurringInvoiceEndDate = recurringObject.lastData;
        inputParam.recurringInvoiceStartDate = recurringObject.startDate;
        inputParam.recurringCount = parseInt(recurringObject.occurrences + "");

        // console.log("inputParam",inputParam);
      }

      props.createInvoiceWithFunction(inputParam);
      tog_standard();
    }
  };
  const getAllReceipentForCurrent = () => {
    let receipentIds = [];
    if (activeFilterKid.length === 1 && activeFilterKid[0].label === "@All") {
      for (const val of kidOptions) {
        if (val.options) {
          for (const option of val.options) {
            receipentIds.push(option.value);
          }
        }
      }
    } else {
      for (const val of activeFilterKid) {
        if (val.value) {
          receipentIds.push(val.value);
        }
      }
    }
    return receipentIds;
  };

  useEffect(() => {
    // if(kidsList.length === 0 && activeFilterKid === 'all'){
    //     selectedNumberOfKidsOps(0)
    // }else if(activeFilterKid === 'all'){
    //     selectedNumberOfKidsOps(kidsList.length)
    // }else{
    //     selectedNumberOfKidsOps(1)
    // }
    //console.log("OKL",kidsList);
    let tempKidList = [];
    let optionsC = [];
    let activeGroupList = [];
    if (activeFilterBy === "group" || activeFilterBy === "program") {
      if (props.groupmaps.length > 0) {
        for (const val of props.groupmaps) {
          activeGroupList.push(val.attributes.userId);
        }
      }
    }
    if (templetRestiction) {

    } else {
      tempKidList.push({ label: "@All", value: 0 });
    }

    if (activeFilterTo !== "all") {
      for (let val of kidsList) {
        if (activeFilterBy === "class") {
          if (
            val.Obj.attributes.ClassRoomID === activeFilterTo ||
            val.Obj.attributes.daycareClassId === activeFilterTo
          ) {
            let temp = {};
            temp.label = val.name;
            temp.value = val.id;
            optionsC.push(temp);
          }
        } else if (activeFilterBy === "group" || activeFilterBy === "program") {
          if (activeGroupList.includes(val.id)) {
            let temp = {};
            temp.label = val.name;
            temp.value = val.id;
            optionsC.push(temp);
          }
        } else if (activeFilterBy === "admission") {
          if (val.Obj.attributes.schoolAdmitted) {
            let temp = {};
            temp.label = val.name;
            temp.value = val.id;
            optionsC.push(temp);
          }
        }
      }
    }
    let optionsA = [];
    if (activeFilterBy === "admission") {
      for (const val of kidsList) {
        if (val.Obj.attributes.schoolAdmitted) {
          let temp = {};
          temp.label = val.name;
          temp.value = val.id;
          optionsC.push(temp);
        }
      }
    } else if (allKid) {
      for (const val of allKid) {
        let temp = {};
        temp.label = val.name;
        temp.value = val.id;
        optionsA.push(temp);
      }
    }
    if (kidsList.length > 0) {
      tempKidList.push({
        label: `All Students`,
        options: optionsC,
      });
    }
    if (allKid && activeFilterTo === "all") {
      if (allKid.length > 0) {
        tempKidList.push({
          label: `All Students`,
          options: optionsA,
        });
      }
    }
    childrenErrorOps("");
    if (activeFilterKid.length === 0) {
      selectedNumberOfKidsOps(0);
    } else if (
      activeFilterKid.length === 1 &&
      activeFilterKid[0].label === "@All"
    ) {
      if (activeFilterBy === "class") {
        if (activeFilterTo === "all") {
          selectedNumberOfKidsOps(optionsA.length);
        } else {
          selectedNumberOfKidsOps(optionsC.length);
        }
      } else if (activeFilterBy === "group" || activeFilterBy === "program") {
        if (activeFilterTo === "all") {
          selectedNumberOfKidsOps(optionsA.length);
        } else {
          selectedNumberOfKidsOps(optionsC.length);
        }
      } else if (activeFilterBy === "admission") {
        selectedNumberOfKidsOps(optionsA.length);
      }
    } else if (activeFilterKid && activeFilterKid.length > 0) {
      selectedNumberOfKidsOps(activeFilterKid.length);
    }
    // activeFilterKidOps([])
    // setselectedMulti(null)
    kidOptionsOps(tempKidList);
    // selectedNumberOfKidsOps(0);
  }, [
    activeFilterKid,
    activeFilterBy,
    activeFilterTo,
    kidsList,
    props.groupmaps,
  ]);
  function handleMulti(selectedMulti) {
    //[{label: 'kidName', value: 'id'}]
    let finalList = [];

    if (templetRestiction) {
      if (!selectedMulti || selectedMulti.length == 0) {
        return
      }
      finalList = [{ label: selectedMulti[selectedMulti.length - 1].label, value: selectedMulti[selectedMulti.length - 1].value }];
      filterTemplet(selectedMulti[selectedMulti.length - 1].value);
    } else
      if (!selectedMulti || selectedMulti.length === 0) {
        finalList = selectedMulti;
      } else if (
        activeFilterKid &&
        activeFilterKid.length === 1 &&
        activeFilterKid[0].label === "@All"
      ) {
        let others = selectedMulti.filter((kid) => kid.label !== "@All");
        finalList = others;
      } else {
        let others = selectedMulti.filter((kid) => kid.label === "@All");

        if (others && others.length > 0) {
          finalList = [{ label: "@All", value: 0 }];
        } else {
          finalList = selectedMulti;
        }
      }
    // if (!finalList || finalList.length === 0) {
    //   finalList = [{ label: "@All", value: 0 }];
    // }
    // setselectedMulti();
    activeFilterKidOps(finalList);
  }


  const getIntervel = (value) => {
    if (value == 30) {
      return "Monthly";
    } else if (value == 90) {
      return "Quarterly"
    } else if (value == 180) {
      return "Half Yearly"
    }
  }

  const filterTemplet = (kidId) => {
    let kids = props.kids.filter((k) => kidId == k.id)
    let kid = kids[0];
    // console.log(kid);

    if(templetRestictionEdit){
      return;
    }

    if (kid) {
      let feeType = kid.attributes.feeType;
      let programType = kid.attributes.programType;
      let corporateId = kid.attributes.corporateId;
      let classroomId = kid.attributes.ClassRoomID;

      let feeTemplates = []

      if (corporateId) {
        feeTemplates = props.feeTemplates.filter(f => f.attributes.corporateId == corporateId && !f.attributes.isDisabled)
      } else {
        feeTemplates = props.feeTemplates.filter(f => !f.attributes.corporateId && !f.attributes.isDisabled)
      }

      if (programType) {
        feeTemplates = feeTemplates.filter(f => f.attributes.programType == programType)
      } else {
        feeTemplates = feeTemplates.filter(f => !f.attributes.programType)
      }

      if (feeType) {
        feeTemplates = feeTemplates.filter(f => f.attributes.feeType == feeType)
      } else {
        feeTemplates = feeTemplates.filter(f => !f.attributes.feeType)
      }


      let classrooms = props.classrooms.filter(c => c.id == classroomId);
      let className = classrooms.length > 0 ? classrooms[0].attributes.Name : "";

      if (className) {
        feeTemplates = feeTemplates.filter(f => (f.attributes.name.toLowerCase().includes(className.toLowerCase()) || className.toLowerCase().includes(f.attributes.name.toLowerCase())));
      } else {
        feeTemplates = feeTemplates.filter(f => !f.attributes.name)
      }


      // console.log(feeTemplates)

      if (feeTemplates) {
        let tempTemplate = [];
        for (let val of feeTemplates) {
          let temp = {};
          temp.Obj = val;
          temp.id = val.id;
          temp.title = val.attributes.name;
          tempTemplate.push(temp);
        }
        feeTemplateOps(tempTemplate);
      } else {
        feeTemplateOps([]);
      }

      if (feeTemplates && feeTemplates.length == 1) {
        activeTemplateOps(feeTemplates[0].id);
        paymentTitleOps(feeTemplates[0].attributes.name);
      } else {
        let tempItems = [];
        let totalAmount = 0;
        setInvoiceTax(0);
        newInvoiceTotalOps(totalAmount);
        invoiceItemListOps(tempItems);
      }

      let today = new Date()
      let isNext = today.getDate() > 14 ? true : false
      if (feeType == "Monthly") {
        if (isNext) {
          let nextMonth = new Date(today.setMonth(today.getMonth() + 1));

          let startDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
          let endDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0, 23, 59);

          setRevenueStartDate(startDate);
          setRevenueEndDate(endDate)
        } else {
          let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          let endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59);

          setRevenueStartDate(startDate);
          setRevenueEndDate(endDate)
        }
      } else if (feeType == "Term") {
        if (isNext) {
          let nextMonth = new Date(today.setMonth(today.getMonth() + 1));
          let startDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
          setRevenueStartDate(startDate);


          let endMonth = new Date(nextMonth.setMonth(nextMonth.getMonth() + 6));
          let endDate = new Date(endMonth.getFullYear(), endMonth.getMonth() + 1, 0, 23, 59);
          setRevenueEndDate(endDate);

        } else {
          let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          setRevenueStartDate(startDate);

          today = new Date();
          let endMonth = new Date(today.setMonth(today.getMonth() + 6));
          let endDate = new Date(endMonth.getFullYear(), endMonth.getMonth() + 1, 0, 23, 59);
          setRevenueEndDate(endDate);

        }
      } else if (feeType == "Yearly") {
        if (isNext) {
          let nextMonth = new Date(today.setMonth(today.getMonth() + 1));
          let startDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
          setRevenueStartDate(startDate);


          let endMonth = new Date(nextMonth.setMonth(nextMonth.getMonth() + 12));
          let endDate = new Date(endMonth.getFullYear(), endMonth.getMonth() + 1, 0, 23, 59);
          setRevenueEndDate(endDate);

        } else {
          let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          setRevenueStartDate(startDate);

          today = new Date();
          let endMonth = new Date(today.setMonth(today.getMonth() + 12));
          let endDate = new Date(endMonth.getFullYear(), endMonth.getMonth() + 1, 0, 23, 59);
          setRevenueEndDate(endDate);

        }
      }

    }
  }

  const canDisable = (val) => {
    if (val.canEdit && templetRestiction) {
      return false
    }

    return completeDisable
  }

  return (
    <React.Fragment>
      {/*new invoice */}
      {showUI &&<Modal
        size="xl"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.selectedSchool && props.t("New Invoice") + " For " + props.selectedSchool.attributes.Name}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          
          <AvForm className="outer-repeater">
            {templetRestiction && <Row>
              <Col>
                <FormGroup>
                  <div className="d-flex justify-content-start" dir="ltr">
                      <input
                        type="checkbox"
                        className="custom-control-input m-1"
                        id="reccuringModal"
                        checked={templetRestictionEdit}
                        onClick={(e) => {
                          setTempletRestictionEdit(!templetRestictionEdit);
                        }}
                      />
                      <label
                        className="custom-control-label m-1"
                        onClick={(e) => {
                          setTempletRestictionEdit(!templetRestictionEdit);
                        }}
                      >
                        Miscellaneous Invoice
                      </label>
                    </div>
                </FormGroup>
              </Col>
            </Row>}
            <Row>
              <Col>
                <FormGroup >
                  <Label className="mb-0">{props.t("Select by")} :</Label>
                  <select
                    className="form-control "
                    value={activeFilterBy}
                    onChange={(e) => {
                      activeFilterByOps(e.target.value);
                      childrenErrorOps("");
                    }}
                  >
                    {filterList.map((val, key) => (
                      <option key={key} value={val}>
                        {props.t(
                          `${val.charAt(0).toUpperCase()}${val.slice(1)}`
                        )}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup >
                  <Label className="mb-0">{props.t("Select to")} :</Label>
                  <select
                    className="form-control "
                    value={activeFilterTo}
                    onChange={(e) => {
                      activeFilterToOps(e.target.value);
                    }}
                    placeholder="Select Program"
                  >
                    <option value="all">{props.t("All")}</option>
                    {filterByList &&
                      filterByList.map((val, key) => (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup >
                  <Label className="mb-0">
                    {props.t("Select by Student")} :
                  </Label>
                  <Select
                    value={activeFilterKid}
                    isMulti={true}
                    onChange={handleMulti}
                    options={kidOptions}
                  // classNamePrefix="select2-selection"
                  // closeMenuOnSelect={false}
                  />
                  <span className="text-danger">{childrenError}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>

              <Col>
                <FormGroup >
                  <Label className="mb-0">{props.t("Select Template")} :</Label>
                  <select
                    className="form-control "
                    value={activeTemplate}
                    onChange={(e) => {
                      activeTemplateOps(e.target.value);
                      for (let val of feeTemplate) {
                        if (val.id === e.target.value) {
                          paymentTitleOps(val.title);
                        }
                      }
                    }}
                    disabled={
                      activeFilterBy === "program" && activeFilterTo !== "all"
                    }
                  >
                    <option value="">Select template</option>
                    {feeTemplate &&
                      feeTemplate.map((val, key) => (
                        <option key={key} value={val.id}>
                          {val.title}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup >
                  <Label className="mb-0">{props.t("Payment Title")} :</Label>
                  <AvField
                    type="text"
                    name="name"
                    placeholder={props.t("Enter enter title...")}
                    className="form-control"
                    value={paymentTitle}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: props.t("Please enter title..."),
                      },
                    }}
                    onChange={(e) => {
                      paymentTitleOps(e.target.value);
                      if (e.target.value) {
                        paymentTitleErrorOps("");
                      }
                    }}
                  />
                  <span className="text-danger">{paymentTitleError}</span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup >
                  <Label className="mb-0">{props.t("Select Year")} :</Label>
                  <select
                    className="form-control"
                    value={activeYear}
                    onChange={(e) => {
                      activeYearOps(e.target.value);
                    }}
                  >
                    {selectlist.map((val, key) => (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {paymentOptions.length > 1 && <Col lg={4}>
                <FormGroup >
                  <Label className="mb-0">{props.t("Payment Account")} </Label>


                  <select
                    className="form-control "
                    value={activeAcc.id}
                    onChange={(e) => {
                      let list = paymentOptions.filter(
                        (item) => item.id === e.target.value
                      );
                      activeAccOps(list[0]);
                    }}
                    placeholder="Select Program"
                  >

                    {
                      paymentOptions.map((val, key) => (
                        <option key={key} value={val.id}>
                          {val.displayName}
                        </option>
                      ))}
                  </select>


                </FormGroup>
              </Col>}

              {receiptAddress.length > 0 && <Col Col lg={4}>
                <FormGroup >
                  <Label className="mb-0 ">{props.t("Receipt Address")} </Label>


                  <select
                    className="form-control "
                    value={selectedAddress.id}
                    onChange={(e) => {
                      let list = receiptAddress.filter(
                        (item) => item.id === e.target.value
                      );
                      if (list[0].object) {
                        setTaxable(list[0].object.attributes.taxable)
                      } else {
                        setTaxable(false)
                      }


                      setSelectedAddress(list[0]);
                    }}
                    placeholder="Select Program"
                  >

                    {
                      receiptAddress.map((val, key) => (
                        <option key={key} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>}

              {false && <Col>
                <FormGroup>
                  <div className="custom-control custom-checkbox mb-2 ml-3 float-right " >
                    <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => false} checked={taxable} />
                    <label className="custom-control-label" onClick={() => {
                      if (taxable) {
                        let istaxed = false
                        for (const ii of invoiceItemList) {
                          if (ii.tax > 0) {
                            istaxed = true;
                          }
                        }

                        if (!istaxed) {
                          setTaxable(!taxable);
                          setTaxError(false);
                        } else {
                          setTaxError(true)
                        }
                      } else {
                        setTaxable(!taxable);
                      }

                    }} >{props.t('GST Invoice')}</label>
                  </div>

                </FormGroup>
                {taxError && <Alert color="info" className="mt-1 text-center" role="alert">
                  <i className="mdi mdi-alert-circle-outline mr-2"></i>
                  Remove GST from all items
                </Alert>}

              </Col>}
              {templetRestiction && <Col lg={4}>
                <FormGroup >
                  <Label className="mb-0">{props.t("Revenue period ")} </Label>


                  <Row>
                    <Col lg={5}>
                      <DatePicker
                        className="form-control "
                        selected={revenueStartDate}
                        onChange={(date) => setRevenueStartDate(date)}
                        selectsStart
                        startDate={revenueStartDate}
                        endDate={revenueEndDate}
                        dateFormat="MMM-yyyy"
                        showMonthYearPicker
                        placeholder={"Revenue Starts on"}
                        maxDate={revenueEndDate}
                      />
                    </Col>
                    <Col lg={2}>
                      <p className="mt-2">to</p>
                    </Col>
                    <Col lg={5}>
                      <DatePicker
                        className="form-control "
                        selected={revenueEndDate}
                        onChange={(date) => setRevenueEndDate(date)}
                        selectsEnd
                        startDate={revenueStartDate}
                        endDate={revenueEndDate}
                        minDate={revenueStartDate}
                        dateFormat="MMM-yyyy"
                        showMonthYearPicker
                        placeholder={"Revenue Ends on"}
                      />
                    </Col>
                  </Row>

                </FormGroup>
              </Col>}
              {true && <Col>
                <FormGroup>
                  <div className="d-flex justify-content-start" dir="ltr">
                    <input type="checkbox"
                      className="custom-control-input m-1"
                      id="reccuringModal"
                      checked={showRecurringModal || (recurringObject ? true : false)}
                      onClick={(e) => { setShowRecurringModal(!showRecurringModal); }}
                    />
                    <label className="custom-control-label m-1"
                      onClick={(e) => { setShowRecurringModal(!showRecurringModal); }}>
                      Recurring Invoice
                    </label>
                  </div>

                  {recurringObject && <div>
                    <p className="mb-0">{"Cylcle: " + getIntervel(recurringObject.selectedIntervel)}</p>
                    <p className="mb-0">{"Recurring starts on: " + Moment(recurringObject.startDate).format("DD/MM/YYYY")}</p>
                    <p className="mb-0">{"Occurrences: " + recurringObject.occurrences}</p>
                    <p className="mb-0">{"Recurring ends on: " + Moment(recurringObject.lastData).format("DD/MM/YYYY")}</p>
                  </div>}
                </FormGroup>
              </Col>}
            </Row>
            <Row>
              <Col>
                <Table className="table table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>{props.t("Slno")}</th>
                      <th>{props.t("Particular")}</th>
                      <th>{props.t("Due Date")}</th>
                      <th>{props.t("Amount")}</th>
                      <th>{props.t("Discount")}</th>
                      <th>{props.t("Discount Remark")}</th>
                      {taxable && <th>{props.t("GST")}</th>}
                      {showRoyalty && <th>{props.t("Royalty")}</th>}
                      <th>{props.t("Item Total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceItemList.map((val, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          <FormGroup>
                            <AvField
                              type="text"
                              name="Particular"
                              placeholder={props.t("Enter Particular...")}
                              className="form-control"
                              value={val.itemDescription}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Please enter particular..."
                                  ),
                                },
                              }}
                              onChange={(e) => {
                                updateInvoiceData(
                                  key,
                                  "itemDescription",
                                  e.target.value
                                );
                              }}
                              disabled={canDisable(val)}
                            />
                            {val.hsn && <p className="text-size-12">{"HSN: " + val.hsn}</p>}

                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <DatePicker
                              selected={val.dueDate}
                              onChange={(date) => {
                                updateInvoiceData(key, "dueDate", date);
                              }}
                              className="form-control"
                              placeholderText={props.t("Selecte Date")}
                              startDate={val.dueDate}
                              dateFormat="dd/MM/yyyy"
                            />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <AvField
                              type="number"
                              name={`particular_amount_${key}`}
                              placeholder={props.t("Particular amount...")}
                              className="form-control"
                              value={val.amount}
                              min={0}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Please enter amount..."
                                  ),
                                },
                              }}
                              onChange={(e) => {
                                updateInvoiceData(
                                  key,
                                  "amount",
                                  e.target.value
                                );
                              }}
                              disabled={canDisable(val)}
                            />
                          </FormGroup>
                        </td>


                        <td style={{ minWidth: "7rem" }}>
                          <InputGroup>
                            <input
                              type="number"
                              className="form-control"
                              value={val.discount}
                              placeholder="Amount"
                              min={0}
                              onChange={(e) => {
                                updateInvoiceData(key, "discount", e.target.value);
                              }}
                            />
                            <div className="input-group-append">
                              <select
                                value={val.discountType}
                                onChange={(e) => {
                                  updateInvoiceData(key, "discountType", e.target.value);
                                }}
                                className="form-select"
                                style={{ fontFamily: "Arial, sans-serif" }}
                              >
                                <option value="1">{currency}</option>
                                <option value="2">%</option>
                              </select>
                            </div>
                          </InputGroup>
                        </td>
                        <td>
                          <FormGroup>
                            <AvField
                              type="text"
                              name="remark"
                              placeholder={props.t("Remark...")}
                              className="form-control"
                              disabled={val.discountEnabled}
                              value={val.discountRemark}

                              onChange={(e) => {
                                updateInvoiceData(
                                  key,
                                  "discountRemark",
                                  e.target.value
                                );
                              }}
                            />
                          </FormGroup>
                        </td>
                        {taxable && <td style={{ minWidth: "5rem" }}>
                          <InputGroup>

                            <div className="input-group-append">
                              {/* <span className="input-group-text">%</span> */}
                              <select
                                value={val.tax}
                                onChange={(e) => {
                                  updateInvoiceData(
                                    key,
                                    "tax",
                                    e.target.value === "0" ? undefined : parseInt(e.target.value)
                                  );
                                }}
                                style={{ fontFamily: "Arial, sans-serif" }}
                              >
                                <option value="0">0%</option>
                                <option value="5">5%</option>
                                <option value="12">12%</option>
                                <option value="18">18%</option>
                                <option value="28">28%</option>

                              </select>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              value={val.taxAmount}
                              placeholder="00"
                              min={0}
                              disabled

                            />
                          </InputGroup>
                        </td>}
                        {showRoyalty && (
                          <td style={{ minWidth: "5rem" }}>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                value={val.royalty ?? 0}
                                placeholder="Royalty"
                                min={0}
                                onChange={(e) => {
                                  updateInvoiceData(key, "royalty", e.target.value);
                                }}
                                disabled={canDisable(val)}
                              />
                              <div className="input-group-append">
                                <select
                                  value="2"
                                  disabled={true}
                                  className="form-select"
                                >
                                  <option value="2">%</option>
                                </select>
                              </div>
                            </div>
                            {val.royalty > 0 && (
                              <p className="text-center text-size-12">
                                {currency}
                                {val.royaltyAmount ?? 0}
                              </p>
                            )}
                          </td>
                        )}
                        <td>
                          <FormGroup>
                            <AvField
                              type="number"
                              name="total"
                              placeholder={props.t("Total...")}
                              className="form-control"
                              disabled={true}
                              value={parseFloat(val.totalAmount).toFixed(2)}

                            />
                          </FormGroup>
                        </td>
                        {(!canDisable(val) || !showRoyalty || templetRestictionEdit) && <td>
                          <i
                            onClick={() => removeItemFromNewInvoice(key)}
                            className="bx bx-message-square-x text-danger h3 mt-1"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </td>}
                      </tr>
                    ))}
                    {invoiceItemList.length === 0 && (
                      <tr>
                        {templetRestictionEdit?<td
                          className="text-center text-Secondary h5 pt-5"
                          colSpan="7"
                        >
                          {props.t("To create invoice start")}
                          <span
                            className="text-info"
                            onClick={addAnItemToInvoice}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="btn btn-info">
                              {" "}
                              {props.t("Adding items")}{" "}
                            </button>
                          </span>
                        </td>:<td
                          className="text-center text-Secondary h5 pt-5"
                          colSpan="7"
                        >
                          {showRoyalty ? props.t("To create invoice select template") : props.t("To create invoice start")}{" "}
                          {!showRoyalty && <span
                            className="text-info"
                            onClick={addAnItemToInvoice}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="btn btn-info">
                              {" "}
                              {props.t("Adding items")}{" "}
                            </button>
                          </span>}
                        </td>}
                      </tr>
                    )}
                    {invoiceItemList.length > 0 && (!showRoyalty || templetRestiction) && (
                      <tr>
                        <td colSpan={taxable && showRoyalty ? "8" : (taxable || showRoyalty) ? "7" : "6"}></td>
                        <td>
                          <button
                            className="btn btn-info"
                            onClick={addAnItemToInvoice}
                            style={{ width: "100%" }}
                          >
                            + Add Item
                          </button>
                        </td>
                      </tr>
                    )}
                    {invoiceItemList.length > 0 && taxable && (
                      <tr>
                        <td colSpan="6"></td>
                        <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("GST")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(newInvoiceTax).toFixed(2)}
                              disabled={true}
                            ></input>
                            <p className="text-danger">{amountError}</p>
                          </FormGroup>
                        </td>
                        {showRoyalty && <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Royalty Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(royaltyAmount).toFixed(2)}
                              disabled={true}
                            ></input>

                          </FormGroup>
                        </td>}
                        <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Total Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(newInvoiceTotal).toFixed(2)}
                              disabled={true}
                            ></input>
                            <p className="text-danger">{amountError}</p>
                          </FormGroup>
                        </td>
                      </tr>
                    )}
                    {invoiceItemList.length > 0 && !taxable && (
                      <tr>
                        <td colSpan={showRoyalty ? "6" : "6"}></td>
                        {showRoyalty && <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Royalty Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(royaltyAmount).toFixed(2)}
                              disabled={true}
                            ></input>

                          </FormGroup>
                        </td>}
                        <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Total Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(newInvoiceTotal).toFixed(2)}
                              disabled={true}
                            ></input>
                            <p className="text-danger">{amountError}</p>
                          </FormGroup>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </AvForm>
        </div>
        <div className="modal-footer table-responsive">
          <Table className="table table-nowrap table-borderless mb-0">
            <thead>
              <tr>
                <td style={{ minWidth: "7rem" }}></td>
                <td style={{ minWidth: "16rem" }} colSpan="2">
                  <h5 className="text-warning float-right mt-2">
                    {props.t("Selected number of students")}:{" "}
                    {selectedNumberOfKids}
                  </h5>
                </td>
                {/* <td style={{minWidth : '8rem'}}></td> */}
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      tog_standard();
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                    style={{ width: "100%" }}
                  >
                    {props.t("Close")}
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={createInvoice}
                    disabled={invoiceItemList.length === 0}
                    style={{ width: "90%" }}
                  >
                    {props.showLoaderCreateInvoice && (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    )}
                    {props.t("Create Invoice")}
                  </button>
                </td>
                <td></td>
              </tr>
            </thead>
          </Table>
        </div>
      </Modal>}
      {showUI && <RecurringInvoicePopup
        showRecurringModal={showRecurringModal}
        recurringObject={recurringObject}
        onCloseRecurringModal={() => { setShowRecurringModal(false) }}
        onSetRecurringObject={(obj) => {
          setRecurringObject(obj)
          setShowRecurringModal(false)
        }}
      />}
      <div className="page-content">
        {/* <Link className="btn btn-success" to="https://www.google.com" target="_blank">Go to Google</Link> */}
        <Breadcrumbs
          title={`Fee Management`}
          popUpmodel={tog_standard}
          teacherPaymenrWrite={teacherPaymenrWrite}
        />
        <Card className="mb-0">
          <CardBody>
            <Nav pills className="navtab-bg nav-justified ">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    activeTabOps("1");
                  }}
                >
                  {props.t("All Invoice").toUpperCase()}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    activeTabOps("2");
                  }}
                >
                  {props.t("Transactions").toUpperCase()}
                </NavLink>
              </NavItem>
              <NavItem
                style={{ cursor: "pointer" }}
                onClick={() => {
                  activeTabOps("3");
                }}
              >
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    activeTabOps("3");
                  }}
                >
                  {props.t("Templates").toUpperCase()}
                </NavLink>
              </NavItem>
              {true && <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    activeTabOps("4");
                  }}
                >
                  {props.t("Recurring Invoices").toUpperCase()}
                </NavLink>
              </NavItem>}
            </Nav>
          </CardBody>
        </Card>
       {showUI && <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <AllInvoice
              classList={classList}
              groupList={groupList}
              programList={programList}
              currency={currency}
              schoolId={schoolId}
              filterList={filterListAllInvoices}
              list={list}
              activeTab={activeTab}
              paymentOptions={paymentOptions}
              teacherPaymenrWrite={teacherPaymenrWrite}
              paymentAdmin={paymentAdmin}
              showRoyalty={showRoyalty}
              completeDisable={completeDisable}
              school={props.selectedSchool}
            />
          </TabPane>
          <TabPane tabId="2">
            {activeTab == "2" &&<AllTransaction
              schoolId={schoolId}
              currency={currency}
              activeTab={activeTab}
              list={list}
              teacherPaymenrWrite={teacherPaymenrWrite}
              paymentAdmin={paymentAdmin}
            />}
          </TabPane>
          <TabPane tabId="3">
            {activeTab =="3" && <SchoolTemplate
              currency={currency}
              activeTab={activeTab}
              schoolId={schoolId}
              teacherPaymenrWrite={teacherPaymenrWrite}
              paymentAdmin={paymentAdmin}
              showRoyalty={showRoyalty}
              corporatePartners={props.corporatePartners}
              showCorporatePartners={showCorporatePartners}
              selectedSchool={props.selectedSchool}
            />}
          </TabPane>
          <TabPane tabId="4">
            {activeTab =="4" && <RecurringInvoices
              currency={currency}
              activeTab={activeTab}
              schoolId={schoolId}
            />}
          </TabPane>
        </TabContent>}
        {!showUI && <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
            </div>
          </Col>
        </Row>}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { kids, admissionKids, allDeletedKids } = state.Kid;
  const { selectedSchool, paymentOptionsForSchool, receiptAddres } = state.School;
  const { classrooms } = state.Classroom;
  const { groups } = state.Group;
  const { programs } = state.Program;
  const { groupmaps } = state.GroupMap;
  const { feeTemplates } = state.FeeTemplate;
  const { feeTemplateItems } = state.FeeTemplateItem;
  const { successResCreateInvoice, showLoaderCreateInvoice } = state.Invoice;
  const { userTeacher } = state.Login;
  const { franchise } = state.Franchise;
  const { corporatePartners } = state.CorporatePartners;
  return {
    kids, admissionKids, allDeletedKids,
    selectedSchool, paymentOptionsForSchool, receiptAddres,
    classrooms,
    groups,
    programs,
    groupmaps,
    feeTemplates,
    feeTemplateItems,
    successResCreateInvoice, showLoaderCreateInvoice,
    userTeacher,
    franchise,
    corporatePartners,
  };
};

export default connect(mapStatetoProps, {
  //getSchool,
  getClassroomsForSchoolId,
  getGroupsForSchoolId,
  getProgramsForSchoolId,
  getAdmissionKidsForSchool,
  getGroupMapsForGroupId,
  getFeeTemplateForSchoolId,
  getFeeTemplateItemsForTemplateId,
  createInvoiceWithFunction,
  getInvoiceItemForInvoiceId,
  getPaymentOptionsForSchool,
  setSelecetdSchool,
  setInvoiceSuccessComplete,
  getUserTeacher,
  getAllKids,
  getAllDeletedKidForSchool,
  getReceiptAddressForSchoolId,
  getFranchise,
  getCorporatePartners,
})(withRouter(withNamespaces()(Payment)));
