import Parse from "parse";
import invoiceItem from "../../store/model/invoice/reducer";
import { printLog } from "./loghelper";
const getInvoiceItemFromParse = (invoiceItemId) => {
  printLog("HL: invoiceItems_helper : getInvoiceItemFromParse")

  try {
    return new Promise((resolve, reject) => {
      var InvoiceItem = Parse.Object.extend("InvoiceItems");
      var query = new Parse.Query(InvoiceItem);
      query.get(invoiceItemId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteInvoiceItemFromParse = (invoiceItemId) => {
  printLog("HL: invoiceItems_helper : deleteInvoiceItemFromParse")

  try {
    return new Promise((resolve, reject) => {
      const InvoiceItem = Parse.Object.extend("InvoiceItems");
      var invoiceItem = new InvoiceItem();
      invoiceItem.id = invoiceItemId;
      invoiceItem.set("deleted", true);
      if (Parse.User.current().attributes.teacherId) {
        invoiceItem.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        invoiceItem.set("deletedBy", Parse.User.current().id);
      }

      invoiceItem.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const getInvoiceItemsForInvoiceId = (id, isLoacal) => {
  printLog("HL: invoiceItems_helper : getInvoiceItemsForInvoiceId")
  try {
    return new Promise((resolve, reject) => {
      var InvoiceItem = Parse.Object.extend("InvoiceItems");
      var query = new Parse.Query(InvoiceItem);
      query.equalTo("invoiceId", id);
      query.ascending('slno');
      query.notEqualTo("deleted", true);
      if (isLoacal) {
        query.fromLocalDatastore();
      }

      query.ascending("Name");
      query.find().then((result) => {
        resolve(result);
      });
    });
  } catch (error) {}
};

export const getItemsForInvoiceIds = (invoiceIds, isForDiscount) => {
  printLog("HL: invoiceItems_helper : getItemsForInvoiceIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("InvoiceItems");
      query.containedIn("invoiceId", invoiceIds);
      query.ascending('slno');
      if (isForDiscount) {
        query.greaterThan("discount", 0);
      }
      let limit = invoiceIds.length*5
      if(limit>1000){
        query.limit(1000);
      }else{
        query.limit(1000);
      }
      
      query.notEqualTo("deleted",true)
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export {
  getInvoiceItemFromParse,
  deleteInvoiceItemFromParse,
  getInvoiceItemsForInvoiceId,
};
